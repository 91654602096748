.contact {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 120px;
  width: 100%;
}
.contact .contact-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-inline: 80px;
}
.contact .contact-form .programs {
  background-color: #f9f3ff;
  border-radius: 8px;
  padding: 48px;
}
.contact .contact-form .programs .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 50%;
}
.contact .contact-form .programs .text p {
  color: #717171;
  font-size: 1.2rem;
}
.contact .contact-form .programs .text h3 {
  font-size: 32px;
}
.contact .contact-form .programs .text span {
  color: #9b51e0;
}
.contact .contact-form .programs .text p {
  font-weight: bolder;
}
.contact .contact-form .form-text {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  justify-content: space-between;
}
.contact .contact-form .form-text .text {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.contact .contact-form .form-text .text h1 {
  color: #9b51e0;
  font-size: 1rem;
}
.contact .contact-form .form-text .text .asking-title {
  color: black;
  font-size: 24px;
}
.contact .contact-form .form-text .text .asking-title span {
  font-weight: bold;
}
.contact .contact-form .form-text .text p {
  color: #9c9c9c;
}
.contact .contact-form .form-text form {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.contact .contact-form .form-text form input,
.contact .contact-form .form-text form textarea {
  width: 100%;
  padding: 14px 24px;
  border-radius: 8px;
  border: 1px solid #ccc;
  outline: none;
  font-size: 14px;
}
.contact .contact-form .form-text form textarea {
  min-height: 200px;
}
.contact .contact-form .form-text form .half-input {
  display: flex;
  gap: 8px;
}

@media screen and (max-width: 900px) {
  .contact {
    width: 100%;
  }
  .contact-form {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 700px) {
  .contact .contact-form {
    padding-inline: 20px;
  }
  .contact .contact-form .form-text {
    display: flex;
  }
}
@media screen and (max-width: 510px) {
  .contact .contact-form form .half-input {
    display: flex;
    flex-direction: column;
  }
}/*# sourceMappingURL=contact.css.map */
@import "../variables/variables.scss";

.subscription-form-container {
  top: -90px;
  margin: auto;
  position: relative;
  padding: 70px;
  border-radius: 10px;
  background-color: #fff;
  font-family: $font_family;
  width: 100%;
  max-width: 1338px;

  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  font-size: 1.2vw;

  .subscription-form {
    display: flex;
    align-items: center;
    gap: 40px;

    .subscribe {
      font-weight: 700;
      font-size: 1.2rem;
      span {
        font-weight: 400;
      }
    }

    form {
      display: flex;
      gap: 16px;
      flex-grow: 1;

      input {
        padding: 18px 16px;
        border-radius: 10px;
        border: 1px solid #e6e6e6;
        outline: none;
        flex-grow: 1;
        max-width: 550px;
        display: flex;
        align-items: center;
        font-family: $font_family;
        font-size: 1rem;
      }

      input::placeholder {
        color: #c0c0c0;
        font-family: $font_family;
      }

      button {
        color: #fff;
        background-color: $primary_color;
        padding: 18px 16px;
        border-radius: 10px;
        border: none;
        flex-shrink: 0;
        font-family: $font_family;
        font-size: 1rem;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 760px) {
  .subscription-form-container {
    .subscription-form {
      flex-direction: column;
      align-items: normal;
      gap: 20px;
    }
  }
}

@media (max-width: 580px) {
  .subscription-form-container {
    width: 80%;
    padding: 44px;
    .subscription-form {
      form {
        flex-wrap: wrap;
      }
    }
  }
}

@media (max-width: 400px) {
  .subscription-form-container {
    padding: 40px;
  }
}

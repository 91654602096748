@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");
.subscription-form-container {
  top: -90px;
  margin: auto;
  position: relative;
  padding: 70px;
  border-radius: 10px;
  background-color: #fff;
  font-family: "Outfit", sans-serif;
  width: 100%;
  max-width: 1338px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  font-size: 1.2vw;
}
.subscription-form-container .subscription-form {
  display: flex;
  align-items: center;
  gap: 40px;
}
.subscription-form-container .subscription-form .subscribe {
  font-weight: 700;
  font-size: 1.2rem;
}
.subscription-form-container .subscription-form .subscribe span {
  font-weight: 400;
}
.subscription-form-container .subscription-form form {
  display: flex;
  gap: 16px;
  flex-grow: 1;
}
.subscription-form-container .subscription-form form input {
  padding: 18px 16px;
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  outline: none;
  flex-grow: 1;
  max-width: 550px;
  display: flex;
  align-items: center;
  font-family: "Outfit", sans-serif;
  font-size: 1rem;
}
.subscription-form-container .subscription-form form input::-moz-placeholder {
  color: #c0c0c0;
  font-family: "Outfit", sans-serif;
}
.subscription-form-container .subscription-form form input::placeholder {
  color: #c0c0c0;
  font-family: "Outfit", sans-serif;
}
.subscription-form-container .subscription-form form button {
  color: #fff;
  background-color: #9B51E0;
  padding: 18px 16px;
  border-radius: 10px;
  border: none;
  flex-shrink: 0;
  font-family: "Outfit", sans-serif;
  font-size: 1rem;
  cursor: pointer;
}

@media (max-width: 760px) {
  .subscription-form-container .subscription-form {
    flex-direction: column;
    align-items: normal;
    gap: 20px;
  }
}
@media (max-width: 580px) {
  .subscription-form-container {
    width: 80%;
    padding: 44px;
  }
  .subscription-form-container .subscription-form form {
    flex-wrap: wrap;
  }
}
@media (max-width: 400px) {
  .subscription-form-container {
    padding: 40px;
  }
}/*# sourceMappingURL=subscription.css.map */
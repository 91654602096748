@import '../../componentStyles/variables/variables.scss';

.contact-card-container{
    font-family: $font_family;
    

    .contact-card{

        
        form{
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 100%;

            .name-input{
                // min-width: 100%;
            }

            .email-and-tel{
                display: flex;
                gap: 20px;
                min-width: 100%;
            }

            
            input, textarea{
                padding: 18px 16px;
                border-radius: 10px;
                border: 1px solid #E6E6E6;
                outline: none;
                flex-grow: 1;
                // max-width: 550px;
                display: flex;
                align-items: center;
                font-family: $font_family;
                font-size: 14px;
                resize: none;
            }

            input::placeholder, textarea::placeholder{
                color:#C0C0C0;
                font-family: $font_family;
            }

            textarea{
                min-height: 196px;
            }

            button{
                color: #fff;
                background-color: $primary_color;
                border-radius: 32px;
                border: none;
                font-family: $font_family;
                font-size: 1rem;
                flex-shrink: 0;
                // max-width: 194px;

            }
        }
    }
}

@media screen and (max-width: 900px) {
    .contact-card-container .contact-card form input, .contact-card-container .contact-card form textarea{
        font-size: 1rem;
    }


}

@media screen and (max-width: 560px) {
    /* .contact-card-container .contact-card form .email-and-tel{
        flex-direction: column;
    } */
    .contact-card-container .contact-card form{
        max-width: 100%;
        .email-and-tel{
            flex-wrap: wrap;
            gap: 12px
        }
    }
}
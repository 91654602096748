@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");
.stories-card-container {
  padding: 20px;
  border-radius: 10px;
  background-color: #8B4BC7;
  font-family: "Outfit", sans-serif;
  font-size: 1.2vw;
  max-width: 600px;
  border-radius: 16px;
  color: #fff;
  display: flex;
  align-items: center;
  margin: auto;
}
.stories-card-container .stories-card {
  display: flex;
  gap: 24px;
  align-items: center;
}
.stories-card-container .stories-card .img-container {
  max-width: 148px;
  width: 100%;
  height: 148px;
  border-radius: 12px;
  overflow: hidden;
  margin-right: 20px;
}
.stories-card-container .stories-card .img-container img {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  -o-object-fit: cover;
     object-fit: cover;
}
.stories-card-container .stories-card .story-details {
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.stories-card-container .stories-card .story-details .name {
  font-weight: 700;
  font-size: 1.1rem;
}
.stories-card-container .stories-card .story-details .role {
  color: #BFBEBE;
}/*# sourceMappingURL=stories.css.map */
@import "../variable/variable.scss";

.responsive-lgn {
  display: none;
}


.language-selector {
  position: relative;
}

.language-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1001;
}

.page-header.light-mode .links a {
  color: black;
  font-size: 1.2vw;

}


.page-header.dark-mode .links a {
  color: white;
  font-size: 1.2vw;
}

.page-header.light-mode .donate-changelgn .language-button {
  color: black;

}

.page-header.dark-mode .donate-changelgn .language-button {
  color: white;

}


.page-header {
  position: sticky;
  top: 0;
  height: 120px;
  z-index: 1000;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  &.scrolled {

    .links a {
      color: #1C1C1C;
      font-size: 1.2vw;
    }

    // a {
    //   color: $primary-color !important;
    // }
    .hamburger {
      div {
        background-color: #2a2d7c;
      }
    }
  }


  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 90vw;
    width: 100%;

    .donate-changelgn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

    }
  }

  .links {
    display: flex;
    gap: 20px;

    a {
      text-decoration: none;
      font-size: 20px;
      text-wrap: wrap;

      &.scrolled {
        color: black;


      }
    }

  }

  .donate-changelgn {
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: space-between;


    .language-selector {
      position: relative;
    }

    .language-button {
      display: flex;
      align-items: center;
      background: transparent;
      border: 2px solid #DEC2F7FA;
      border-radius: 32px;
      font-size: 16px;
      cursor: pointer;
      justify-content: space-between;
      gap: 8px;

      &.light-mode {
        color: $primary-color;
      }


    }


    .language-dropdown {
      position: absolute;
      top: 100%;
      right: 0;
      width: 100%;
      background: #fff;
      border-radius: 15px;
      overflow: hidden;
      z-index: 999;
      border-radius: 8px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      margin-top: 10px;



      button {
        width: 100%;
        padding: 12px 16px;
        background: none;
        border: none;
        color: #333;
        text-align: left;
        cursor: pointer;
        transition: background-color 0.3s ease;
        font-size: 16px;
        border-bottom: 1px solid #ddd;
        border-radius: 0px;

        &:hover {
          background-color: #f0f0f0;
        }

      }

    }
  }

}

.donate-changelgn .language-button,
.donate-btn {
  padding: 12px 24px;
  font-size: 16px;
}

.donate-changelgn {
  display: flex;
  align-items: center;
}


.donate-changelgn .language-button {
  width: auto;
}

.page-header .light-mode .scrolled .language-button {
  color: $primary-color;

}

.react-icon {
  color: white;

  &.scrolled {
    color: $primary-color;
  }

  &.light-mode {
    color: $primary-color;
  }
}

.react-icon .light-mode {
  color: $primary-color;
}

.donate-btn {
  background: transparent;
  border: 2px solid #DEC2F7FA;
  border-radius: 32px;
  color: #fff;
  font-size: 16px;
  color: #9B51E0;
  cursor: pointer;
  justify-content: center;
  gap: 8px;
  margin-top: 20px;
  width: 100%;


}



.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  z-index: 11;

  div {
    width: 27px;
    height: 4px;
    background-color: #fff;
    margin: 3px 0;
    transition: all 0.3s;
    border-radius: 20px;
  }
}

.hamburger.open {
  div {
    background-color: #2a2d7c;
    width: 28px;

  }


  div:nth-child(1) {
    transform: rotate(40deg) translate(9px, 9px);
  }

  div:nth-child(2) {
    opacity: 0;
  }

  div:nth-child(3) {
    transform: rotate(-46deg) translate(5px, -5px);
  }
}

.hide-respon {
  display: flex;
  gap: 15px;
  align-items: center;
  padding-top: 20px;
}

.cancel {
  display: none;
}

.language-button {
  display: flex;
  align-items: center;
  gap: 8px;
}

.language-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  background: #fff;
  border-radius: 15px;
  overflow: hidden;
  z-index: 999;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 10px;

  button {
    width: 100%;
    padding: 12px 16px;
    background: none;
    border: none;
    color: #333;
    text-align: left;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 16px;
    border-bottom: 1px solid #ddd;
    border-radius: 0px;

    &:hover {
      background-color: #f0f0f0;
    }

  }
}


@media screen and (max-width: 1250px) {
  .hide-respon {
    padding-top: 0px;
  }

  .cancel {
    right: 40px;
    position: absolute;
    display: block;

  }

  .nav-menu {
    display: none;
  }

  .hamburger {
    display: flex;
  }

  .nav-menu.responsive {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 1000;
    overflow-y: auto;
  }

  .page-header {
    nav {
      justify-content: space-between;
      gap: 40px;
    }

    .links {
      flex-direction: column;
      padding-top: 40px;
      align-items: center;
      width: 100%;
      justify-content: center;
      text-align: center;

      a {
        padding: 10px;
        border-bottom: 1px solid white;
        width: 100%;
      }
    }
  }


}


@media screen and (max-width: 900px) {
  .hide-respon {
    display: none;
  }

  .responsive-lgn {
    display: block;
  }

  .page-header.scrolled .links a {
    font-size: 1.2rem;
  }

}
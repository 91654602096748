@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");
.section-title {
  font-family: "Outfit", sans-serif;
  flex-direction: column;
  gap: 24px;
  display: flex;
}
.section-title .section-heading {
  color: #9B51E0;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 700;
}
.section-title .sub-title {
  font-weight: 700;
  font-size: 1.4rem;
}
.section-title .sub-title span {
  font-weight: 400;
}/*# sourceMappingURL=main.css.map */
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");
.event-card-container {
  background-color: #FAFAFA;
  padding: 16px 24px;
  border-radius: 10px;
  border: 1px solid #F5F5F5;
  font-family: "Outfit", sans-serif;
  cursor: pointer;
}
.event-card-container .event-card {
  display: flex;
  align-items: center;
  gap: 16px;
}
.event-card-container .event-card .date {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: #669900;
  padding: 11px 18px;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media screen and (max-width: 900px) {
  .event-card-container {
    max-width: none;
  }
}/*# sourceMappingURL=eventsCard.css.map */
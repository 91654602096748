.contact {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 120px;
  width: 100%;

  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-inline: 80px;

    .programs {
      background-color: #f9f3ff;
      border-radius: 8px;
      padding: 48px;
      
      .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12px;
        width: 50%;
        

        p {
          color: #717171;
          font-size: 1.2rem
        }

        h3 {
          font-size: 32px;
        }

        span {
          color: #9b51e0;
        }

        p {
          font-weight: bolder;
        }
      }
    }

    .form-text {
      display: flex;
      gap: 24px;
      flex-wrap: wrap;
      justify-content: space-between;

      .text {
        display: flex;
        flex-direction: column;
        gap: 24px;

        h1 {
          color: #9b51e0;
          font-size: 1rem;
        }

        .asking-title {
          color: black;
          font-size: 24px;

          span {
            font-weight: bold;
          }
        }

        p {
          color: #9c9c9c;
        }
      }

      form {
        display: flex;
        flex-direction: column;
        gap: 12px;

        input,
        textarea {
          width: 100%;
          padding: 14px 24px;
          border-radius: 8px;
          border: 1px solid #ccc;
          outline: none;
          font-size: 14px;
        }

        textarea {
          min-height: 200px;
        }

        .half-input {
          display: flex;
          gap: 8px;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .contact {
    width: 100%;
  }
  .contact-form {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 700px) {
  .contact {
    .contact-form {
      .form-text {
        display: flex;
      }
      padding-inline: 20px;
    }
  }
}

@media screen and (max-width: 510px) {
  .contact {
    .contact-form {
      form {
        .half-input {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}

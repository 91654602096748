@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");
a {
  text-decoration: none;
}

.landing-page {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
}
.landing-page .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  text-align: center;
  padding-inline: 80px;
  padding-block: 80px;
  position: relative;
}
.landing-page .container .language-selector .language-button {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #fff;
  border: 1px solid #9B51E0;
  color: #9B51E0;
  padding: 12px 24px;
}
.landing-page .container .language-selector .language-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  background: #fff;
  border-radius: 15px;
  overflow: hidden;
  z-index: 999;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}
.landing-page .container .language-selector .language-dropdown button {
  width: 100%;
  padding: 12px 16px;
  background: none;
  border: none;
  color: #333;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 14px;
  border-bottom: 1px solid #ddd;
  border-radius: 0px;
}
.landing-page .container .language-selector .language-dropdown button:hover {
  background-color: #f0f0f0;
}
.landing-page .container .circle-decoration {
  position: absolute;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background-color: rgba(135, 0, 140, 0.05);
  overflow: visible;
}
.landing-page .container .circle-decoration .circle-decoration-small {
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: rgba(135, 0, 140, 0.1);
}
.landing-page .container .circle-decoration.left {
  position: absolute;
  bottom: -100px;
  left: -150px;
}
.landing-page .container .circle-decoration.left .circle-decoration-small {
  top: 200px;
  right: -30px;
}
.landing-page .container .circle-decoration.right {
  position: absolute;
  bottom: -100px;
  right: -150px;
}
.landing-page .container .circle-decoration.right .circle-decoration-small {
  top: 200px;
  left: -30px;
}
.landing-page .container .text-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.landing-page .container .text-container h1 {
  color: #87008C;
  font-weight: 500;
}
.landing-page .container .buttons {
  display: flex;
  align-items: center;
  gap: 24px;
}
.landing-page .container .buttons .button {
  background: #9B51E0;
  border: none;
  border-radius: 32px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  justify-content: center;
  gap: 8px;
  margin-top: 20px;
  padding: 12px 24px;
}
.landing-page .container .info {
  color: #000;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 25px;
}
.landing-page .container .info .general-inquirie {
  display: flex;
  align-items: center;
  width: 100%;
}
.landing-page .container .info .general-inquirie .icon-inquirie {
  width: 32px;
  height: 32px;
}
.landing-page .container .info .general-inquirie .heading2 {
  width: 100%;
  max-width: 300px;
  height: 30px;
}
.landing-page .container .info .divider {
  width: 5px;
  height: 60px;
}
.landing-page .container .info .info-inquirie {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.landing-page .container .frames img {
  position: absolute;
  left: 0;
  bottom: 0;
}/*# sourceMappingURL=landing.css.map */
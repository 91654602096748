@import "../../componentStyles/variables/variables.scss";

.faqs-card-container {
  background-color: #fff;
  padding: 16px 24px;
  border-radius: 10px;
  border: 1px solid #f5f5f5;
  font-family: $font_family;
  display: flex;
  flex-direction: column;
  align-self: flex-start;

  .faqs-card {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;

    .question-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;

      .number-and-question {
        display: flex;
        align-items: center;
        gap: 24px;

        .number {
          padding: 16px 24px;
          background-color: #f4f6ff;
          border-radius: 50%;
          color: #95a4fc;
        }

        .question {
          margin: 0;
        }
      }

      .plus-and-minus {
        color: #95a4fc;
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }

    .answer {
      color: #8b8b8b;
      margin: 0;
      padding-left: calc(24px + 24px + 24px);
    }
  }
}

@media screen and (max-width: 900px) {
  .faqs-card-container {
    max-width: 100%;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");
.faqs-card-container {
  background-color: #fff;
  padding: 16px 24px;
  border-radius: 10px;
  border: 1px solid #f5f5f5;
  font-family: "Outfit", sans-serif;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
}
.faqs-card-container .faqs-card {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
}
.faqs-card-container .faqs-card .question-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.faqs-card-container .faqs-card .question-header .number-and-question {
  display: flex;
  align-items: center;
  gap: 24px;
}
.faqs-card-container .faqs-card .question-header .number-and-question .number {
  padding: 16px 24px;
  background-color: #f4f6ff;
  border-radius: 50%;
  color: #95a4fc;
}
.faqs-card-container .faqs-card .question-header .number-and-question .question {
  margin: 0;
}
.faqs-card-container .faqs-card .question-header .plus-and-minus {
  color: #95a4fc;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.faqs-card-container .faqs-card .answer {
  color: #8b8b8b;
  margin: 0;
  padding-left: 72px;
}

@media screen and (max-width: 900px) {
  .faqs-card-container {
    max-width: 100%;
  }
}/*# sourceMappingURL=faqsCard.css.map */
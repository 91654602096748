.event-details {
    .container {
        padding-top: 160px;

        .event-details-container {
            display: flex;
            align-items: baseline;
            flex-wrap: wrap;
            gap: 3rem;

            .title-image {
                flex: 1 1 300px;
                display: grid;
                height: fit-content;
                gap: 1rem;

                .image-placeholder {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #F9F3FF;
                    height: 450px;
                    border-radius: 1rem;

                    img {
                        max-width: 50px;
                    }
                }

                .event-image {
                    width: 100%;
                    height: 450px;
                    object-fit: cover;
                    border-radius: 1rem;
                }
            }

            .details {
                flex: 1 1 300px;
                display: grid;
                gap: 2rem;
                height: fit-content;

                .time-location {
                    background-color: whitesmoke;
                    padding: 2rem;
                    border-radius: 1rem;
                    color: gray;

                    display: flex;
                    align-items: center;
                    gap: 2rem;
                    flex-wrap: wrap;

                    .start-date,
                    .end-date,
                    .location {
                        display: flex;
                        align-items: center;
                        gap: 0.3rem;

                        p {
                            color: rgb(44, 44, 44);
                        }
                    }
                }
            }
        }
    }
}
.sub {
  margin-top: 150px;
  flex-direction: column;
  background-color: #9B51E0;
  padding-inline: 60px;
  padding-block: 80px;
  padding-top: 20px;
}

.footer-main {
  width: 100%;
  display: flex;
  gap: 80px;
  justify-content: center;
}
.footer-main .img-text {
  background-image: url("../images/footer-img.png");
  background-repeat: no-repeat;
  width: 556px;
  height: 296px;
  display: flex;
  padding-left: 40px;
  padding-right: 20px;
  padding-top: 40px;
  border-radius: 16px;
}
.footer-main .img-text .location-call {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.footer-main .img-text .icon-text {
  display: flex;
  gap: 20px;
  color: white;
}
.footer-main .img-text .icon-text p {
  font-size: 14px;
  font-weight: 500;
}
.footer-main .img-text .icon-text h2 {
  font-size: 16px;
  font-weight: 700;
}
.footer-main .img-text .icon-text .email {
  font-size: 20px;
  font-weight: 500;
}
.footer-main .img-text .icon-text .actual-text {
  display: flex;
  gap: 5px;
  flex-direction: column;
}
.footer-main .img-text .icon-text .icon-footer {
  background-color: white;
  padding: 10px;
  padding-inline: 12px;
  justify-content: center;
  align-items: center;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 12px;
}
.footer-main .useful-links {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.footer-main .useful-links h1 {
  font-size: 20px;
  font-weight: 500;
  color: white;
}
.footer-main .useful-links .links-text {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  text-wrap: nowrap;
}
.footer-main .useful-links .links-text .left-links, .footer-main .useful-links .links-text .right-links {
  display: flex;
  gap: 15px;
  flex-direction: column;
}
.footer-main .useful-links .links-text .left-links a, .footer-main .useful-links .links-text .right-links a {
  text-decoration: none;
  font-size: 18px;
  color: white;
}
.footer-main .programs-footer {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.footer-main .programs-footer h1 {
  font-size: 20px;
  font-weight: 500;
  color: white;
}
.footer-main .programs-footer .white-part .value {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  height: 80px;
  border-radius: 12px;
  padding-inline: 14px;
  background-color: #fbf8fe;
}
.footer-main .programs-footer .white-part .value .image-div {
  max-width: 46px;
  width: 100%;
  height: 46px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: linear-gradient(89.96deg, rgba(0, 0, 0, 0.58) 0.04%, rgba(0, 0, 0, 0) 99.97%), url("/public/images/big-image.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.footer-main .programs-footer .white-part .value .program-title {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.footer-main .programs-footer .white-part .value .program-title .title {
  width: 100%;
  font-weight: 700;
  cursor: pointer;
}
.footer-main .programs-footer .white-part .value .program-title .title:hover {
  color: #C0C0C0;
}

@media (max-width: 1160px) {
  .footer-main {
    gap: 40px;
  }
}
@media (max-width: 1100px) {
  .footer-main {
    display: grid;
    grid-template-columns: 60% 30%;
  }
  .footer-main .img-text {
    width: 100%;
  }
}
@media (max-width: 850px) {
  .footer-main .useful-links .links-text {
    grid-template-columns: 50% 60%;
  }
}
@media (max-width: 780px) {
  .footer-main {
    display: flex;
    flex-direction: column;
  }
  .footer-main .useful-links .links-text {
    grid-template-columns: 20% 20%;
    text-wrap: nowrap;
  }
}
@media (max-width: 580px) {
  .sub {
    padding-inline: 0px;
  }
  .footer-main .useful-links {
    padding-left: 20px;
  }
  .footer-main .programs-footer {
    padding-inline: 20px;
  }
  .footer-main .programs-footer .white-part {
    width: 100%;
  }
  .footer-main .useful-links .links-text {
    gap: 40px;
  }
}
@media (max-width: 380px) {
  .footer-main .img-text {
    padding-left: 15px;
  }
}/*# sourceMappingURL=footer.css.map */
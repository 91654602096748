.event-details .container {
  padding-top: 160px;
}
.event-details .container .event-details-container {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  gap: 3rem;
}
.event-details .container .event-details-container .title-image {
  flex: 1 1 300px;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
}
.event-details .container .event-details-container .title-image .image-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F9F3FF;
  height: 450px;
  border-radius: 1rem;
}
.event-details .container .event-details-container .title-image .image-placeholder img {
  max-width: 50px;
}
.event-details .container .event-details-container .title-image .event-image {
  width: 100%;
  height: 450px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 1rem;
}
.event-details .container .event-details-container .details {
  flex: 1 1 300px;
  display: grid;
  gap: 2rem;
  height: -moz-fit-content;
  height: fit-content;
}
.event-details .container .event-details-container .details .time-location {
  background-color: whitesmoke;
  padding: 2rem;
  border-radius: 1rem;
  color: gray;
  display: flex;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
}
.event-details .container .event-details-container .details .time-location .start-date,
.event-details .container .event-details-container .details .time-location .end-date,
.event-details .container .event-details-container .details .time-location .location {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}
.event-details .container .event-details-container .details .time-location .start-date p,
.event-details .container .event-details-container .details .time-location .end-date p,
.event-details .container .event-details-container .details .time-location .location p {
  color: rgb(44, 44, 44);
}/*# sourceMappingURL=eventDetails.css.map */
@import '../componentStyles/variables/variables.scss';

.section-title{
    font-family: $font_family;
    flex-direction: column;
    gap: 24px;
    display: flex;

    .section-heading{
        color: #9B51E0;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 700;
    }

    .sub-title{
        font-weight: 700;
        font-size: 1.4rem; 
        span{
            font-weight: 400;
        }
    }
    
}
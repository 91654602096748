@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&family=Quicksand:wght@300..700&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

.container {
  padding-inline: 80px;
  width: 100%;
  max-width: 100%;
  padding-block: 50px;
}

.charity-home .hero {
  background: linear-gradient(89.96deg, rgba(0, 0, 0, 0.758) 0.04%, rgba(0, 0, 0, 0.457) 99.97%), url("/public/images/home/charity-heling-hands-canada.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.charity-home .hero a {
  color: #fff;
}
.charity-home .hero-section .container {
  height: calc(100vh - 120px);
  display: flex;
  align-items: center;
  position: relative;
  color: #fff;
  padding-bottom: 200px;
}
.charity-home .hero-section .container .hero-text {
  display: flex;
  flex-direction: column;
  align-content: center;
  gap: 24px;
  max-width: 800px;
  width: 100%;
  padding: 20px;
}
.charity-home .hero-section .container .hero-text .heanding1 {
  font-weight: 700;
  font-size: 2rem;
}
.charity-home .hero-section .container .hero-text .paragraph {
  color: #eeeeee;
  font-size: 1.2rem;
  font-weight: 400;
}
.charity-home .hero-section .container .hero-text .button {
  color: #fff;
  background-color: #9B51E0;
  padding: 18px 16px;
  border-radius: 32px;
  border: none;
  font-family: "Outfit", sans-serif;
  font-size: 1rem;
  flex-shrink: 0;
  max-width: 257px;
  text-align: center;
  font-weight: 500;
}
.charity-home .hero-section .inquiries-info {
  max-width: 700px;
  width: 100%;
  background-color: white;
  border-radius: 24px 24px 0 0;
  position: absolute;
  z-index: 100;
  right: 80px;
  bottom: -2px;
  color: #000;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 25px;
}
.charity-home .hero-section .inquiries-info .general-inquirie {
  display: flex;
  align-items: center;
  gap: 24px;
  max-width: 500px;
  width: 100%;
}
.charity-home .hero-section .inquiries-info .general-inquirie .icon-inquirie {
  width: 32px;
  height: 32px;
}
.charity-home .hero-section .inquiries-info .general-inquirie .heading2 {
  width: 100%;
  max-width: 300px;
  height: 30px;
}
.charity-home .hero-section .inquiries-info .divider {
  width: 1px;
  height: 60px;
  background-color: #d9d9d9;
}
.charity-home .hero-section .inquiries-info .info-inquirie {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.charity-home .hero-section .inquiries-info .info-inquirie .paragraph {
  max-width: 450px;
  width: 100%;
  height: 20px;
}
.charity-home .hero-section .inquiries-info .info-inquirie .short-p {
  width: 200px;
}
.charity-home .faqs {
  background-color: #f6f7ff;
}
.charity-home .faqs .container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.charity-home .faqs .container .headind4 {
  color: #9b51e0;
  font-weight: 700;
  font-size: 1.2rem;
}
.charity-home .faqs .container .our-program-title {
  display: flex;
  gap: 24px;
  justify-content: space-between;
  align-items: center;
}
.charity-home .faqs .container .our-program-title .heading1 {
  font-family: "Quicksand", sans-serif;
  font-size: 1.5rem;
}
.charity-home .faqs .container .our-program-title .button {
  color: #9b51e0;
  text-transform: uppercase;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
}
.charity-home .faqs .container .faq-questions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 24px;
}
.charity-home .faqs .container .faq-questions .questions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  max-width: 620px;
  width: 100%;
  height: 125px;
  border-radius: 10px;
  padding: 16px;
  background-color: white;
}
.charity-home .faqs .container .faq-questions .questions .paragraphs-number {
  display: flex;
  align-items: center;
  max-width: 470px;
  width: 100%;
  gap: 24px;
}
.charity-home .faqs .container .faq-questions .questions .paragraphs-number .number {
  max-width: 45px;
  width: 100%;
  height: 45px;
  background-color: #b4b0b9;
  border-radius: 50%;
}
.charity-home .faqs .container .faq-questions .questions .paragraphs-number .paragraphs {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 400px;
  width: 100%;
  height: 92px;
}
.charity-home .faqs .container .faq-questions .questions .paragraphs-number .paragraphs .paragraph1 {
  max-width: 280px;
  width: 100%;
  height: 20px;
  background-color: #b4b0b9;
}
.charity-home .faqs .container .faq-questions .questions .paragraphs-number .paragraphs .paragraph2 {
  max-width: 400px;
  width: 100%;
  height: 60px;
  background-color: #b4b0b9;
}
.charity-home .faqs .container .faq-questions .questions .icon {
  max-width: 24px;
  width: 100%;
  height: 24px;
  background-color: #b4b0b9;
}
.charity-home .call-of-action {
  width: 100%;
  min-height: 435px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  background: url("/public/images/home/volunters.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5921568627);
  background-blend-mode: overlay;
}
.charity-home .call-of-action .heading1 {
  font-weight: 700;
  font-size: 1.5rem;
}
.charity-home .call-of-action .paragraph {
  max-width: 560px;
  width: 100%;
  font-size: 1.3rem;
  text-align: center;
  font-weight: 400;
}
.charity-home .call-of-action .button {
  padding: 18px 20px;
  border-radius: 32px;
  background-color: #fff;
  color: #9b51e0;
  font-weight: 500;
}
.charity-home .our-stories {
  width: 100%;
  background-color: #9b51e0;
  color: #fff;
}
.charity-home .our-stories .container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.charity-home .our-stories .container .stories-title {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 16px;
}
.charity-home .our-stories .container .stories-title .story-heading1 {
  font-weight: 700;
  font-size: 1.2rem;
  text-transform: uppercase;
}
.charity-home .our-stories .container .stories-title .paragraph {
  max-width: 540px;
  width: 100%;
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
}
.charity-home .our-stories .container .stories-title .paragraph span {
  font-weight: 400;
}
.charity-home .our-stories .container .stories {
  display: flex;
  gap: 32px;
}
.charity-home .our-stories .container .stories .story {
  max-width: 665px;
  width: 100%;
}
.charity-home .our-stories .container .stories .story .story-image-text {
  display: flex;
  align-items: center;
  gap: 24px;
  max-width: 663px;
  width: 100%;
  height: 241px;
  border-radius: 16px;
  background-color: #e1dde6;
  padding: 32px;
}
.charity-home .our-stories .container .stories .story .story-image-text .storie-image {
  max-width: 148px;
  width: 100%;
  height: 148px;
  background-color: #b4b0b9;
  border-radius: 12px;
}
.charity-home .our-stories .container .stories .story .story-image-text .story-text {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 367px;
  width: 100%;
  height: 177px;
  background-color: #e1dde6;
}
.charity-home .our-stories .container .stories .story .story-image-text .story-text .heading4 {
  max-width: 111px;
  width: 100%;
  height: 23px;
  background-color: #b4b0b9;
}
.charity-home .our-stories .container .stories .story .story-image-text .story-text .heading6 {
  width: 100%;
  max-width: 50px;
  height: 18px;
  background-color: #b4b0b9;
}
.charity-home .our-stories .container .stories .story .story-image-text .story-text .paragraph {
  max-width: 367px;
  width: 100%;
  height: 120px;
  width: 100%;
  background-color: #b4b0b9;
}
.charity-home .our-stories .container .story-button {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  gap: 10px;
  font-weight: 600;
  justify-content: center;
}
.charity-home .body-section .about-us .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  padding-inline: 80px;
}
.charity-home .body-section .about-us .container .about-us-images {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  width: 100%;
  max-width: 630px;
  width: 100%;
  position: relative;
}
.charity-home .body-section .about-us .container .about-us-images .purple-background {
  position: absolute;
  background-image: url("/public/images/mission-background.svg");
  background-size: cover;
  background-position: center;
  z-index: -1;
  top: 50px;
  left: -36px;
  right: 33px;
  bottom: 45px;
}
.charity-home .body-section .about-us .container .about-us-images .big-image {
  background-color: #e1dde6;
  max-width: 300px;
  width: 100%;
  height: 450px;
  border-radius: 0 0 0 40px;
  background: url("/public/images/home/charity helping hands vision.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.charity-home .body-section .about-us .container .about-us-images .small-image-touche {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 280px;
  width: 100%;
  height: 450px;
}
.charity-home .body-section .about-us .container .about-us-images .small-image-touche .small-image {
  margin-top: 35px;
  background: url("/public/images/home/claudia-raya-iYtYZ5H-9ws-unsplash.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  max-width: 175px;
  width: 100%;
  height: 275px;
  border-radius: 0 40px 0 0;
}
.charity-home .body-section .about-us .container .about-us-images .small-image-touche .touche {
  display: flex;
  align-items: center;
  gap: 24px;
  background-color: #f7f1ff;
  width: 100%;
  max-width: 285px;
  height: 100px;
  border-radius: 0 0 40px 0;
  padding: 16px;
}
.charity-home .body-section .about-us .container .about-us-images .small-image-touche .touche .icon {
  width: 80px;
  height: 60px;
}
.charity-home .body-section .about-us .container .about-us-images .small-image-touche .touche .text-touche {
  display: flex;
  flex-direction: column;
  gap: 6px;
  max-width: 185px;
  width: 100%;
  height: 54px;
  background-color: #f7f1ff;
}
.charity-home .body-section .about-us .container .about-us-images .small-image-touche .touche .text-touche .heading1 {
  max-width: 60px;
  width: 100%;
  height: 28px;
  color: #9B51E0;
  font-weight: 700;
  font-size: 1.5rem;
}
.charity-home .body-section .about-us .container .about-us-images .small-image-touche .touche .text-touche .heading3 {
  max-width: 181px;
  width: 100%;
  height: 20px;
  height: 28px;
  color: #626262;
  font-weight: 500;
  font-size: 1rem;
}
.charity-home .body-section .about-us .container .about-us-text {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 590px;
  width: 100%;
}
.charity-home .body-section .about-us .container .about-us-text .paragraph {
  max-width: 300px;
  width: 100%;
}
.charity-home .body-section .about-us .container .about-us-text .heading1 {
  max-width: 300px;
  width: 100%;
}
.charity-home .body-section .about-us .container .about-us-text .paragraph2 {
  max-width: 588px;
  width: 100%;
  color: #8b8b8b;
  font-size: 1rem;
}
.charity-home .body-section .about-us .container .about-us-text .button {
  color: #fff;
  background-color: #9B51E0;
  padding: 18px 16px;
  border-radius: 32px;
  border: none;
  font-family: "Outfit", sans-serif;
  font-size: 1rem;
  flex-shrink: 0;
  max-width: 257px;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  cursor: pointer;
}
.charity-home .body-section .our-values-container {
  padding-inline: 80px;
}
.charity-home .body-section .our-values {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-inline: 80px;
  padding-block: 50px;
  width: 100%;
  background: url("/public/images/our-values.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 24px;
  color: #fff;
  gap: 15px;
}
.charity-home .body-section .our-values .heading1 {
  color: #9B51E0;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.2rem;
}
.charity-home .body-section .our-values .heading3 {
  font-size: 1.2rem;
  max-width: 700px;
  text-align: center;
  width: 100%;
}
.charity-home .body-section .our-goals .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  padding-inline: 80px;
}
.charity-home .body-section .our-goals .container .our-goal-image {
  max-width: 650px;
  width: 100%;
  border-radius: 24px;
}
.charity-home .body-section .our-goals .container .our-goal-image img {
  max-width: 100%;
  height: auto;
  border-radius: 24px;
}
.charity-home .body-section .our-goals .container .our-goal-text {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}
.charity-home .body-section .our-goals .container .our-goal-text .goal {
  color: #9B51E0;
  font-weight: 700;
  font-size: 1.2rem;
}
.charity-home .body-section .our-goals .container .our-goal-text .heading3 {
  max-width: 560px;
  width: 100%;
  font-weight: 700;
  font-size: 1.2rem;
}
.charity-home .body-section .our-goals .container .our-goal-text .paragraph {
  max-width: 560px;
  width: 100%;
  color: #4f4f4f;
  font-size: 1rem;
}
.charity-home .body-section .our-goals .container .our-goal-text .goals {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 570px;
  width: 100%;
  border-radius: 16px;
  padding: 24px;
  background-color: #fcf8ff;
}
.charity-home .body-section .our-goals .container .our-goal-text .goals .heading4 {
  max-width: 100%;
  color: #9B51E0;
  font-weight: 700;
  font-size: 1rem;
}
.charity-home .body-section .our-goals .container .our-goal-text .goals .paragraph {
  max-width: 527px;
  width: 100%;
  color: #4f4f4f;
}
.charity-home .body-section .our-impact {
  width: 100%;
  height: 100%;
}
.charity-home .body-section .our-impact .container {
  background-color: #ffffff;
  width: 100%;
  padding-block: 100px;
}
.charity-home .body-section .our-impact .container .impacts {
  display: flex;
  gap: 24px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}
.charity-home .body-section .our-impact .container .impacts .impact-text {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
}
.charity-home .body-section .our-impact .container .impacts .impact-text .title {
  max-width: 150px;
  width: 100%;
  height: 24px;
}
.charity-home .body-section .our-impact .container .impacts .impact-text .heading2 {
  max-width: 431px;
  width: 100%;
  height: 90px;
}
.charity-home .body-section .our-impact .container .impacts .impact-text .paragraph {
  max-width: 530px;
  width: 100%;
  color: #3f3f3f;
}
.charity-home .body-section .our-impact .container .impacts .impact-text .impact-text-value {
  display: flex;
  gap: 16px;
}
.charity-home .body-section .our-impact .container .impacts .impact-text .impact-text-value .value {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  height: 80px;
  border-radius: 12px;
  padding-inline: 14px;
  background-color: #fbf8fe;
  text-align: start;
}
.charity-home .body-section .our-impact .container .impacts .impact-text .impact-text-value .value .icon {
  max-width: 46px;
  width: 100%;
  height: 46px;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}
.charity-home .body-section .our-impact .container .impacts .impact-text .impact-text-value .value .value-text-number {
  max-width: 165px;
  width: 100%;
  height: 53px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.charity-home .body-section .our-impact .container .impacts .impact-text .impact-text-value .value .value-text-number .numbers {
  font-weight: 700;
  font-size: 1.2rem;
}
.charity-home .body-section .our-impact .container .impacts .impact-text .impact-text-value .value .value-text-number .paragraph {
  max-width: 165px;
  width: 100%;
  height: 40px;
  color: #636363;
}
.charity-home .body-section .our-impact .container .impacts .impact-image {
  display: flex;
  align-items: center;
  height: 100%;
  max-width: 700px;
  width: 50%;
  position: relative;
  z-index: 0;
}
.charity-home .body-section .our-impact .container .impacts .impact-image .impact-big-img {
  max-width: 530px;
  width: 100%;
  border-radius: 12px;
  padding-left: 100px;
}
.charity-home .body-section .our-impact .container .impacts .impact-image .impact-big-img img {
  width: 100%;
  height: auto;
  border-radius: 12px;
}
.charity-home .body-section .our-impact .container .impacts .impact-image .impact-small-img {
  max-width: 200px;
  width: 100%;
  border-radius: 12px;
  position: absolute;
  z-index: 2;
}
.charity-home .body-section .our-impact .container .impacts .impact-image .impact-small-img img {
  width: 100%;
  height: auto;
  border-radius: 12px;
}
.charity-home .body-section .our-events .container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-inline: 80px;
}
.charity-home .body-section .our-events .container .headind4 {
  color: #9b51e0;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.2rem;
}
.charity-home .body-section .our-events .container .our-program-title {
  display: flex;
  gap: 24px;
  justify-content: space-between;
  align-items: center;
}
.charity-home .body-section .our-events .container .our-program-title .heading1 {
  font-family: "Quicksand", sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
}
.charity-home .body-section .our-events .container .our-program-title .heading1 span {
  font-weight: 300;
}
.charity-home .body-section .our-events .container .our-program-title .button {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #9b51e0;
  text-transform: uppercase;
}
.charity-home .body-section .our-events .container .events-image-text {
  display: flex;
  justify-content: space-between;
  height: 100%;
  gap: 50px;
}
.charity-home .body-section .our-events .container .events-image-text .first-row {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.charity-home .body-section .our-events .container .events-image-text .second-row {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;
  gap: 24px;
}
.charity-home .body-section .our-events .container .events-image-text .second-row .event-card-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.charity-home .body-section .our-events .container .events-image-text .event-image {
  max-width: 586px;
  width: 100%;
  height: 270px;
  overflow: hidden;
  border-radius: 10px;
}
.charity-home .body-section .our-events .container .events-image-text .event-image img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  -o-object-fit: cover;
     object-fit: cover;
}
.charity-home .body-section .shops .container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.charity-home .body-section .shops .container .heading4-shop {
  max-width: 180px;
  width: 100%;
  height: 24px;
  background-color: #e1dde6;
}
.charity-home .body-section .shops .container .heading1-shop {
  max-width: 607px;
  width: 100%;
  height: 50px;
  background-color: #e1dde6;
}
.charity-home .body-section .shops .container .our-program-title {
  display: flex;
  gap: 24px;
  justify-content: space-between;
  align-items: center;
}
.charity-home .body-section .shops .container .our-program-title .heading1 {
  color: #8b8b8b;
  max-width: 688px;
  width: 100%;
}
.charity-home .body-section .shops .container .our-program-title .paragraph {
  max-width: 645px;
  width: 100%;
  height: 48px;
  background-color: #e1dde6;
}
.charity-home .body-section .shops .container .our-program-title .button {
  color: #9B51E0;
  text-transform: uppercase;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
}
.charity-home .body-section .contact-us .container {
  width: 100%;
  display: flex;
  gap: 24px;
  justify-content: space-between;
}
.charity-home .body-section .contact-us .container .contact-us-text {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.charity-home .body-section .contact-us .container .contact-us-text .headind4 {
  max-width: 180px;
  width: 100%;
  height: 24px;
  background-color: #e1dde6;
}
.charity-home .body-section .contact-us .container .contact-us-text .our-program-title {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.charity-home .body-section .contact-us .container .contact-us-text .our-program-title .heading1 {
  max-width: 539px;
  width: 100%;
  height: 50px;
  background-color: #e1dde6;
}
.charity-home .body-section .contact-us .container .contact-us-text .our-program-title .paragraph {
  max-width: 473px;
  width: 100%;
  color: #9c9c9c;
}
.charity-home .body-section button,
.charity-home .body-section .button {
  cursor: pointer;
}
.charity-home .our-programs {
  background-color: #fcf8ff;
  width: 100%;
}
.charity-home .our-programs .container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.charity-home .our-programs .container .headind4 {
  color: #9b51e0;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.2rem;
}
.charity-home .our-programs .container .our-program-title {
  display: flex;
  gap: 24px;
  justify-content: space-between;
  align-items: center;
}
.charity-home .our-programs .container .our-program-title .heading1 {
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
}
.charity-home .our-programs .container .our-program-title .heading1 span {
  font-weight: 300;
}
.charity-home .our-programs .container .our-program-title .button {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #9b51e0;
  text-transform: uppercase;
  cursor: pointer;
}
.charity-home .our-programs .container .programs {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
}
.charity-home .our-programs .container .programs .card {
  flex: 1 1 300px !important;
}
.charity-home .our-programs .container .programs .program {
  max-width: 415px;
  width: 100%;
  height: 600px;
  background-color: #fbfbfb;
  border-radius: 24px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.charity-home .our-programs .container .programs .program .image-button-text {
  max-width: 365px;
  width: 100%;
  height: 220px;
  background-color: #e1dde6;
  border-radius: 8px;
  position: relative;
}
.charity-home .our-programs .container .programs .program .image-button-text .image {
  max-width: 365px;
  width: 100%;
  height: 220px;
  background-color: #e1dde6;
  border-radius: 8px;
  position: relative;
  z-index: 100;
}
.charity-home .our-programs .container .programs .program .image-button-text .button {
  max-width: 135px;
  width: 100%;
  height: 42px;
  background-color: #b4b0b9;
  border-radius: 8px;
  padding: 8px 16px;
  position: absolute;
  z-index: 150;
  top: 20px;
  right: 15px;
}
.charity-home .our-programs .container .programs .program .paragraph {
  width: 100%;
  max-width: 365px;
  height: 65px;
  background-color: #e1dde6;
}
.charity-home .our-programs .container .programs .program .goals-raised {
  display: flex;
  align-items: center;
  max-width: 365px;
  width: 100%;
  height: 60px;
  background-color: #e1dde6;
  padding: 16px 24px;
  gap: 16px;
}
.charity-home .our-programs .container .programs .program .goals-raised .goal-number {
  max-width: 150px;
  width: 100%;
  height: 30px;
  background-color: #b4b0b9;
  border-radius: 8px;
}
.charity-home .our-programs .container .programs .program .goals-raised .goal-raised {
  max-width: 150px;
  width: 100%;
  height: 30px;
  background-color: #b4b0b9;
  border-radius: 8px;
}
.charity-home .our-programs .container .programs .program .progrssive-bar {
  display: flex;
  gap: 12px;
  align-items: center;
  width: 100%;
  max-width: 365px;
  background-color: #e1dde6;
  height: 30px;
  padding: 8px 12px;
}
.charity-home .our-programs .container .programs .program .progrssive-bar .progress-percentage {
  max-width: 20px;
  width: 100%;
  height: 15px;
  background-color: #b4b0b9;
}
.charity-home .our-programs .container .programs .program .progrssive-bar .progress-bar {
  max-width: 330px;
  width: 100%;
  height: 15px;
  background-color: #b4b0b9;
  border-radius: 8px;
}
.charity-home .our-programs .container .programs .program .progress-button {
  max-width: 365px;
  width: 100%;
  height: 50px;
  background-color: #b4b0b9;
  border-radius: 26px;
  padding: 8px 16px;
}

@media screen and (max-width: 1060px) {
  body {
    font-size: 18px;
  }
  .contact-forms {
    width: 100%;
  }
  .charity-home .body-section .our-events .container .events-image-text .event-image {
    max-width: none;
  }
  .charity-home .body-section .our-goals .container .our-goal-text {
    align-items: center;
    text-align: center;
  }
  .charity-home .body-section .our-goals .container .our-goal-text .goal {
    text-transform: uppercase;
  }
  .charity-home .body-section .our-goals .container .our-goal-text .goals {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .charity-home .body-section .our-goals .container .our-goal-text .goals .heading4 {
    text-transform: uppercase;
  }
  .charity-home .hero-section .container .hero-text .button {
    font-size: 0.9rem;
  }
  .charity-home .body-section .our-goals .container .our-goal-image {
    max-width: 600px;
    width: 100%;
  }
  .charity-home .hero-section {
    padding-inline: 10px;
  }
  .charity-home .hero-section .general-inquirie {
    justify-content: center;
  }
  .charity-home .hero-section .inquiries-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    right: 0;
    justify-content: center;
    align-items: center;
  }
  .charity-home .hero-section .inquiries-info .info-inquirie {
    max-width: 350px;
    width: 100%;
  }
  .charity-home .hero-section .inquiries-info .divider {
    height: 1px;
    width: 100%;
  }
  .charity-home .shops .container .faq-questions,
  .charity-home .our-events .container .faq-questions,
  .charity-home .faqs .container .faq-questions {
    grid-template-columns: 1fr;
  }
  .charity-home .stories {
    flex-wrap: wrap;
  }
  .charity-home .shops-images-texts {
    flex-wrap: wrap;
  }
  .charity-home .about-us .container,
  .charity-home .our-goals .container,
  .charity-home .contact-us .container {
    flex-wrap: wrap;
  }
  .charity-home .about-us .container .contact-us-text,
  .charity-home .our-goals .container .contact-us-text,
  .charity-home .contact-us .container .contact-us-text {
    width: 100%;
  }
  .charity-home .charity-home .body-section .about-us .container {
    flex-direction: column;
  }
  .charity-home .about-us .container,
  .charity-home .our-goals .container {
    flex-direction: column-reverse;
  }
  .charity-home .our-impact .container .impacts {
    flex-direction: column;
  }
  .charity-home .our-impact .container .impacts .impact-image {
    width: 100%;
  }
  .charity-home .our-programs .container .programs {
    flex-wrap: wrap;
  }
  .charity-home .our-events .container .events-image-text {
    flex-wrap: wrap;
  }
  .charity-home .body-section .shops .container .our-program-title,
  .charity-home .faqs .container .our-program-title {
    flex-direction: column;
    align-items: start;
  }
  .charity-home .body-section .our-values .heading3 {
    font-size: 1rem;
  }
  .charity-home .body-section .about-us .container .about-us-text,
  .charity-home .hero-section .container .hero-text,
  .charity-home .body-section .our-impact .container .impacts .impact-text,
  .charity-home .body-section .shops .container {
    text-align: center;
    align-items: center;
  }
  .charity-home .body-section .about-us .container .about-us-text .button,
  .charity-home .hero-section .container .hero-text .button,
  .charity-home .body-section .our-impact .container .impacts .impact-text .button,
  .charity-home .body-section .shops .container .button {
    padding: 20px 32px;
    font-size: 0.9rem;
  }
  .charity-home .body-section .our-impact .container .impacts {
    gap: 50px;
  }
  .charity-home .body-section .our-impact .container .impacts .impact-text .impact-text-value {
    flex-wrap: wrap;
  }
  .charity-home .body-section .our-impact .container .impacts .impact-text .impact-text-value .value {
    justify-content: center;
  }
  .charity-home .body-section .about-us .container .about-us-images {
    max-width: 400px;
    width: 100%;
  }
  .charity-home .body-section .contact-us .container .contact-us-text .our-program-title .paragraph {
    max-width: 100%;
  }
  .charity-home .body-section .our-events .container .events-image-text {
    gap: 24px;
  }
  .charity-home .body-section .our-impact .container .impacts .impact-text {
    width: 100%;
  }
  .charity-home .body-section .our-impact .container .impacts .impact-image {
    width: 100%;
  }
  .charity-home .body-section .about-us .container,
  .charity-home .body-section .our-values-container,
  .charity-home .body-section .our-goals .container,
  .charity-home .body-section .our-events .container,
  .charity-home .body-section .our-impact .container,
  .charity-home .our-stories .container .stories,
  .charity-home .faqs {
    padding-inline: 20px;
    width: 100%;
  }
  .charity-home .body-section .our-impact .container .impacts .impact-text .impact-text-value .value .value-text-number .paragraph {
    font-size: 1rem;
  }
  .charity-home .body-section .our-events .container .our-program-title .heading1 {
    font-size: 1.3rem;
  }
}
@media screen and (min-width: 1500px) {
  .charity-home .body-section .about-us .container,
  .charity-home .body-section .our-goals .container,
  .charity-home .body-section .our-impact .container,
  .charity-home .body-section .contact-us .container,
  .charity-home .faqs .container,
  .charity-home .body-section .shops .container,
  .charity-home .our-stories .container,
  .body-section .our-events .container,
  .charity-home .our-programs .container,
  .charity-home .body-section .our-values-container {
    max-width: 1500px;
    margin: auto;
    width: 100%;
  }
}
@media screen and (max-width: 900px) {
  .container {
    padding-inline: 20px;
    width: 100%;
    max-width: 100%;
    padding-block: 20px;
  }
  .events-image-text .events {
    display: flex;
    flex-direction: column;
    gap: 10rem;
  }
  .events-image-text .events .event img {
    width: 100%;
    height: 200px;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 1rem;
  }
  .events-image-text .events .event .event-content {
    top: 6rem;
  }
  .events-image-text .events .event .event-content h3,
  .events-image-text .events .event .event-content p,
  .events-image-text .events .event .event-content .date-location,
  .events-image-text .events .event .event-content .location {
    width: 100%;
    font-size: 0.9rem;
  }
  .events-image-text .events .event .event-content .event-buttons {
    gap: 0.5rem;
  }
  .events-image-text .events .event .event-content .event-buttons button,
  .events-image-text .events .event .event-content .event-buttons .button {
    padding: 1rem 1rem;
    font-size: 0.8rem;
  }
  .charity-home .body-section .our-events .container {
    padding-bottom: 100px;
  }
}
@media screen and (max-width: 1337px) {
  .events-image-text {
    flex-direction: column;
  }
  .events-image-text .first-row,
  .events-image-text .second-row {
    width: 100%;
  }
  .events-image-text .event-image {
    max-width: 100%;
  }
  .events-image-text .event-content h3,
  .events-image-text .event-content p,
  .events-image-text .event-content .date-location,
  .events-image-text .event-content .location {
    width: 96%;
    font-size: 0.9rem;
  }
  .events-image-text .event-content .event-buttons {
    gap: 0.5rem;
  }
  .events-image-text .event-content .event-buttons button,
  .events-image-text .event-content .event-buttons .button {
    padding: 1rem 1rem;
    font-size: 0.8rem;
  }
  .charity-home .body-section .our-events .container .events-image-text .event-image {
    max-width: none;
  }
  .event-card-container {
    max-width: none;
  }
  .charity-home .body-section .our-events .container .events-image-text {
    gap: 24px;
  }
}
@media screen and (max-width: 1179px) {
  .event-content h3,
  .event-content p,
  .event-content .date-location,
  .event-content .location {
    width: 90%;
    font-size: 0.9rem;
  }
  .event-content .event-buttons {
    gap: 0.5rem;
  }
  .event-content .event-buttons button,
  .event-content .event-buttons .button {
    padding: 1rem 1rem;
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 550px) {
  .charity-home .our-programs .container {
    align-items: center;
  }
  .charity-home .our-programs .container .our-program-title {
    flex-direction: column;
    align-items: center;
  }
  .charity-home .our-programs .container .our-program-title .button {
    display: none;
  }
  .charity-home .our-programs .container .our-program-title .button {
    display: none;
  }
  .charity-home .body-section .our-impact .container {
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .stories-card-container .stories-card {
    flex-direction: column;
    text-align: center;
  }
  .charity-home .body-section .shops .container {
    padding-bottom: 20px;
  }
  .charity-home .body-section .contact-us .container {
    padding-bottom: 20px;
  }
  .charity-home .faqs .container {
    padding-bottom: 40px;
  }
  .charity-home .body-section .our-events .container {
    padding-bottom: 180px;
  }
  .faqs .container {
    padding-inline: 0px;
  }
  .container {
    height: -moz-fit-content;
    height: fit-content;
  }
  .events-image-text .events {
    display: flex;
    flex-direction: column;
    gap: 14rem;
  }
  .events-image-text .events .event img {
    width: 100%;
    height: 200px;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 1rem;
  }
  .events-image-text .events .event .event-content {
    top: 7rem;
  }
  .events-image-text .events .event .event-buttons {
    gap: 0.5rem;
  }
  .events-image-text .events .event .event-buttons button,
  .events-image-text .events .event .event-buttons .button {
    padding: 1rem 1rem;
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 450px) {
  .events-image-text .events {
    display: flex;
    flex-direction: column;
    gap: 14rem;
  }
  .events-image-text .events .event img {
    width: 100%;
    height: 170px;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 1rem;
  }
  .events-image-text .events .event .event-content {
    top: 6rem;
    left: 0.6rem;
    right: 0.6rem;
    padding: 1.2rem;
  }
  .events-image-text .events .event .event-content h3,
  .events-image-text .events .event .event-content p,
  .events-image-text .events .event .event-content .date-location,
  .events-image-text .events .event .event-content .location {
    width: 94%;
    font-size: 0.9rem;
  }
  .events-image-text .events .event .event-content .event-buttons {
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
  }
  .events-image-text .events .event .event-content .event-buttons button,
  .events-image-text .events .event .event-content .event-buttons .button {
    width: 100%;
    padding: 0.8rem 1rem;
    font-size: 0.8rem;
  }
  .charity-home .body-section .our-impact .container .impacts .impact-image .impact-big-img {
    padding-left: 100px;
    max-width: 650px;
    width: 100%;
    height: 200px;
  }
  .charity-home .body-section .our-impact .container .impacts .impact-image .impact-small-img {
    max-width: 170px;
    width: 100%;
    border-radius: 12px;
    position: absolute;
    z-index: 2;
    height: 150px;
  }
  .charity-home .body-section .our-impact .container .impacts .impact-image .impact-small-img img {
    width: 100%;
    height: auto;
    border-radius: 12px;
  }
  .charity-home .body-section .our-values {
    padding-inline: 30px;
  }
  .body-section .container .our-impact .impact-image {
    display: flex;
    align-items: center;
    height: 100%;
    max-width: 700px;
    width: 50%;
    position: relative;
    z-index: 0;
  }
}
/* @media screen and (max-width: 380px) {
  .charity-home
    .body-section
    .our-impact
    .container
    .impacts
    .impact-image
    .impact-big-img {
    padding-left: 100px;
    max-width: 650px;
    width: 100%;
    height: 190px;
  }
  .charity-home
    .body-section
    .our-impact
    .container
    .impacts
    .impact-image
    .impact-small-img {
    max-width: 140px;
    width: 100%;
    border-radius: 12px;
    position: absolute;
    z-index: 2;
    img {
      width: 100%;
      height: auto;
      border-radius: 12px;
    }
  }

}
 */
.events {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}
.events .event {
  flex: 1 1 300px;
  position: relative;
}
.events .event img {
  width: 100%;
  height: 400px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 1rem;
}
.events .event .event-content {
  position: absolute;
  top: 10rem;
  left: 2rem;
  right: 2rem;
  background-color: rgb(245, 245, 245);
  padding: 2rem;
  border-radius: 1rem;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
}
.events .event .event-content .date,
.events .event .event-content .location {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.events .event .event-content .date small,
.events .event .event-content .location small {
  color: gray;
}/*# sourceMappingURL=home_layout.css.map */
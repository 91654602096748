.responsive-lgn {
  display: none;
}

.language-selector {
  position: relative;
}

.language-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1001;
}

.page-header.light-mode .links a {
  color: black;
  font-size: 1.2vw;
}

.page-header.dark-mode .links a {
  color: white;
  font-size: 1.2vw;
}

.page-header.light-mode .donate-changelgn .language-button {
  color: black;
}

.page-header.dark-mode .donate-changelgn .language-button {
  color: white;
}

.page-header {
  position: sticky;
  top: 0;
  height: 120px;
  z-index: 1000;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-header.scrolled .links a {
  color: #1C1C1C;
  font-size: 1.2vw;
}
.page-header.scrolled .hamburger div {
  background-color: #2a2d7c;
}
.page-header nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 90vw;
  width: 100%;
}
.page-header nav .donate-changelgn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.page-header .links {
  display: flex;
  gap: 20px;
}
.page-header .links a {
  text-decoration: none;
  font-size: 20px;
  text-wrap: wrap;
}
.page-header .links a.scrolled {
  color: black;
}
.page-header .donate-changelgn {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: space-between;
}
.page-header .donate-changelgn .language-selector {
  position: relative;
}
.page-header .donate-changelgn .language-button {
  display: flex;
  align-items: center;
  background: transparent;
  border: 2px solid rgba(222, 194, 247, 0.9803921569);
  border-radius: 32px;
  font-size: 16px;
  cursor: pointer;
  justify-content: space-between;
  gap: 8px;
}
.page-header .donate-changelgn .language-button.light-mode {
  color: #9B51E0;
}
.page-header .donate-changelgn .language-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  background: #fff;
  border-radius: 15px;
  overflow: hidden;
  z-index: 999;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}
.page-header .donate-changelgn .language-dropdown button {
  width: 100%;
  padding: 12px 16px;
  background: none;
  border: none;
  color: #333;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px;
  border-bottom: 1px solid #ddd;
  border-radius: 0px;
}
.page-header .donate-changelgn .language-dropdown button:hover {
  background-color: #f0f0f0;
}

.donate-changelgn .language-button,
.donate-btn {
  padding: 12px 24px;
  font-size: 16px;
}

.donate-changelgn {
  display: flex;
  align-items: center;
}

.donate-changelgn .language-button {
  width: auto;
}

.page-header .light-mode .scrolled .language-button {
  color: #9B51E0;
}

.react-icon {
  color: white;
}
.react-icon.scrolled {
  color: #9B51E0;
}
.react-icon.light-mode {
  color: #9B51E0;
}

.react-icon .light-mode {
  color: #9B51E0;
}

.donate-btn {
  background: transparent;
  border: 2px solid rgba(222, 194, 247, 0.9803921569);
  border-radius: 32px;
  color: #fff;
  font-size: 16px;
  color: #9B51E0;
  cursor: pointer;
  justify-content: center;
  gap: 8px;
  margin-top: 20px;
  width: 100%;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  z-index: 11;
}
.hamburger div {
  width: 27px;
  height: 4px;
  background-color: #fff;
  margin: 3px 0;
  transition: all 0.3s;
  border-radius: 20px;
}

.hamburger.open div {
  background-color: #2a2d7c;
  width: 28px;
}
.hamburger.open div:nth-child(1) {
  transform: rotate(40deg) translate(9px, 9px);
}
.hamburger.open div:nth-child(2) {
  opacity: 0;
}
.hamburger.open div:nth-child(3) {
  transform: rotate(-46deg) translate(5px, -5px);
}

.hide-respon {
  display: flex;
  gap: 15px;
  align-items: center;
  padding-top: 20px;
}

.cancel {
  display: none;
}

.language-button {
  display: flex;
  align-items: center;
  gap: 8px;
}

.language-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  background: #fff;
  border-radius: 15px;
  overflow: hidden;
  z-index: 999;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}
.language-dropdown button {
  width: 100%;
  padding: 12px 16px;
  background: none;
  border: none;
  color: #333;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px;
  border-bottom: 1px solid #ddd;
  border-radius: 0px;
}
.language-dropdown button:hover {
  background-color: #f0f0f0;
}

@media screen and (max-width: 1250px) {
  .hide-respon {
    padding-top: 0px;
  }
  .cancel {
    right: 40px;
    position: absolute;
    display: block;
  }
  .nav-menu {
    display: none;
  }
  .hamburger {
    display: flex;
  }
  .nav-menu.responsive {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 1000;
    overflow-y: auto;
  }
  .page-header nav {
    justify-content: space-between;
    gap: 40px;
  }
  .page-header .links {
    flex-direction: column;
    padding-top: 40px;
    align-items: center;
    width: 100%;
    justify-content: center;
    text-align: center;
  }
  .page-header .links a {
    padding: 10px;
    border-bottom: 1px solid white;
    width: 100%;
  }
}
@media screen and (max-width: 900px) {
  .hide-respon {
    display: none;
  }
  .responsive-lgn {
    display: block;
  }
  .page-header.scrolled .links a {
    font-size: 1.2rem;
  }
}/*# sourceMappingURL=header.css.map */
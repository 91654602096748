@import "../../componentStyles/variables/variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&family=Quicksand:wght@300..700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

.container {
  padding-inline: 80px;
  width: 100%;
  max-width: 100%;
  padding-block: 50px;
}

.charity-home {
  .hero {
    background: linear-gradient(89.96deg,
        rgba(0, 0, 0, 0.758) 0.04%,
        rgba(0, 0, 0, 0.457) 99.97%),
      url("/public/images/home/charity-heling-hands-canada.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    a {
      color: #fff;
    }
  }

  .hero-section {
    .container {
      height: calc(100vh - 120px);
      display: flex;
      align-items: center;
      position: relative;
      color: #fff;
      padding-bottom: 200px;

      .hero-text {
        display: flex;
        flex-direction: column;
        align-content: center;
        gap: 24px;
        max-width: 800px;
        width: 100%;
        padding: 20px;

        .heanding1 {
          font-weight: 700;
          font-size: 2rem;
        }

        .paragraph {
          color: #eeeeee;
          font-size: 1.2rem;
          font-weight: 400;
        }

        .button {
          color: #fff;
          background-color: $primary_color;
          padding: 18px 16px;
          border-radius: 32px;
          border: none;
          font-family: $font_family;
          font-size: 1rem;
          flex-shrink: 0;
          max-width: 257px;
          text-align: center;
          font-weight: 500;
        }
      }
    }

    .inquiries-info {
      max-width: 700px;
      width: 100%;
      background-color: #ffff;
      border-radius: 24px 24px 0 0;
      position: absolute;
      z-index: 100;
      right: 80px;
      bottom: -2px;
      color: #000;
      font-weight: 500;

      display: flex;
      align-items: center;
      gap: 24px;
      padding: 25px;

      .general-inquirie {
        display: flex;
        align-items: center;
        gap: 24px;
        max-width: 500px;
        width: 100%;

        .icon-inquirie {
          width: 32px;
          height: 32px;
        }

        .heading2 {
          width: 100%;
          max-width: 300px;
          height: 30px;
        }
      }

      .divider {
        width: 1px;
        height: 60px;
        background-color: #d9d9d9;
      }

      .info-inquirie {
        width: 100%;
        max-width: 600px;
        display: flex;
        flex-direction: column;
        gap: 4px;

        .paragraph {
          max-width: 450px;
          width: 100%;
          height: 20px;
        }

        .short-p {
          width: 200px;
        }
      }
    }
  }

  .faqs {
    background-color: #f6f7ff;

    .container {
      display: flex;
      flex-direction: column;
      gap: 24px;
      // margin: auto;
      // max-width: 1200px;

      .headind4 {
        color: #9b51e0;
        font-weight: 700;
        font-size: 1.2rem;
      }

      .our-program-title {
        display: flex;
        gap: 24px;
        justify-content: space-between;
        align-items: center;

        .heading1 {
          font-family: "Quicksand", sans-serif;
          font-size: 1.5rem;
        }

        .button {
          color: #9b51e0;
          text-transform: uppercase;
          font-weight: 600;
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }

      .faq-questions {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        gap: 24px;

        .questions {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 16px;
          max-width: 620px;
          width: 100%;
          height: 125px;
          border-radius: 10px;
          padding: 16px;
          background-color: #ffff;

          .paragraphs-number {
            display: flex;
            align-items: center;
            max-width: 470px;
            width: 100%;
            // background-color: #e1dde6;
            gap: 24px;

            .number {
              max-width: 45px;
              width: 100%;
              height: 45px;
              background-color: #b4b0b9;
              border-radius: 50%;
            }

            .paragraphs {
              display: flex;
              flex-direction: column;
              gap: 16px;
              max-width: 400px;
              width: 100%;
              height: 92px;

              .paragraph1 {
                max-width: 280px;
                width: 100%;
                height: 20px;
                background-color: #b4b0b9;
              }

              .paragraph2 {
                max-width: 400px;
                width: 100%;
                height: 60px;
                background-color: #b4b0b9;
              }
            }
          }

          .icon {
            max-width: 24px;
            width: 100%;
            height: 24px;
            background-color: #b4b0b9;
          }
        }
      }
    }
  }

  .call-of-action {
    width: 100%;
    min-height: 435px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    background: url("/public/images/home/volunters.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    color: #fff;
    background-color: #00000097;
    background-blend-mode: overlay;

    .heading1 {
      font-weight: 700;
      font-size: 1.5rem;
    }

    .paragraph {
      max-width: 560px;
      width: 100%;
      font-size: 1.3rem;
      text-align: center;
      font-weight: 400;
    }

    .button {
      padding: 18px 20px;
      border-radius: 32px;
      background-color: #fff;
      color: #9b51e0;
      font-weight: 500;
    }
  }

  .our-stories {
    width: 100%;
    background-color: #9b51e0;
    color: #fff;

    .container {
      display: flex;
      // background-color: #b4b0b9;
      flex-direction: column;
      // align-items: center;
      gap: 32px;
      // max-width: 1200px;
      // margin: auto;

      .stories-title {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        gap: 16px;

        .story-heading1 {
          font-weight: 700;
          font-size: 1.2rem;
          text-transform: uppercase;
        }

        .paragraph {
          max-width: 540px;
          width: 100%;
          font-family: "Quicksand", sans-serif;
          font-weight: 700;
          font-size: 1.5rem;

          span {
            font-weight: 400;
          }
        }
      }

      .stories {
        display: flex;
        gap: 32px;

        .story {
          max-width: 665px;
          width: 100%;

          .story-image-text {
            display: flex;
            align-items: center;
            gap: 24px;
            max-width: 663px;
            width: 100%;
            height: 241px;
            border-radius: 16px;
            background-color: #e1dde6;
            padding: 32px;

            .storie-image {
              max-width: 148px;
              width: 100%;
              height: 148px;
              background-color: #b4b0b9;
              border-radius: 12px;
            }

            .story-text {
              display: flex;
              flex-direction: column;
              gap: 8px;
              max-width: 367px;
              width: 100%;
              height: 177px;
              background-color: #e1dde6;

              .heading4 {
                max-width: 111px;
                width: 100%;
                height: 23px;
                background-color: #b4b0b9;
              }

              .heading6 {
                width: 100%;
                max-width: 50px;
                height: 18px;
                background-color: #b4b0b9;
              }

              .paragraph {
                max-width: 367px;
                width: 100%;
                height: 120px;
                width: 100%;
                background-color: #b4b0b9;
              }
            }
          }
        }
      }

      .story-button {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        gap: 10px;
        font-weight: 600;
        justify-content: center;
      }
    }
  }

  .body-section {
    .about-us {

      // max-width: 1200px;
      // margin: auto;
      .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 32px;
        padding-inline: 80px;

        .about-us-images {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 24px;
          width: 100%;
          max-width: 630px;
          width: 100%;
          // height: 500px;
          position: relative;

          .purple-background {
            position: absolute;
            background-image: url("/public/images/mission-background.svg");
            background-size: cover;
            background-position: center;
            z-index: -1;
            top: 50px;
            left: -36px;
            right: 33px;
            bottom: 45px;
          }

          .big-image {
            background-color: #e1dde6;
            max-width: 300px;
            width: 100%;
            height: 450px;
            border-radius: 0 0 0 40px;
            background: url("/public/images/home/charity helping hands vision.jpg");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
          }

          .small-image-touche {
            display: flex;
            flex-direction: column;
            gap: 16px;
            max-width: 280px;
            width: 100%;
            height: 450px;

            .small-image {
              margin-top: 35px;
              background: url("/public/images/home/claudia-raya-iYtYZ5H-9ws-unsplash.jpg");
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
              max-width: 175px;
              width: 100%;
              height: 275px;
              border-radius: 0 40px 0 0;
            }

            .touche {
              display: flex;
              align-items: center;
              gap: 24px;
              background-color: #f7f1ff;
              width: 100%;
              max-width: 285px;
              height: 100px;
              border-radius: 0 0 40px 0;
              padding: 16px;

              .icon {
                width: 80px;
                height: 60px;
              }

              .text-touche {
                display: flex;
                flex-direction: column;
                gap: 6px;
                max-width: 185px;
                width: 100%;
                height: 54px;
                background-color: #f7f1ff;

                .heading1 {
                  max-width: 60px;
                  width: 100%;
                  height: 28px;
                  color: $primary_color;
                  font-weight: 700;
                  font-size: 1.5rem;
                }

                .heading3 {
                  max-width: 181px;
                  width: 100%;
                  height: 20px;
                  height: 28px;
                  color: #626262;
                  font-weight: 500;
                  font-size: 1rem;
                }
              }
            }
          }
        }

        .about-us-text {
          display: flex;
          flex-direction: column;
          gap: 24px;
          max-width: 590px;
          width: 100%;
          // height: 380px;
          // background-color: #ffedff;

          .paragraph {
            max-width: 300px;
            width: 100%;
            // height: 24px;
          }

          .heading1 {
            max-width: 300px;
            width: 100%;
            // height: 50px;
          }

          .paragraph2 {
            max-width: 588px;
            width: 100%;
            color: #8b8b8b;
            // font-size: 1.2rem;
            font-size: 1rem;
          }

          .button {
            color: #fff;
            background-color: $primary_color;
            padding: 18px 16px;
            border-radius: 32px;
            border: none;
            font-family: $font_family;
            font-size: 1rem;
            flex-shrink: 0;
            max-width: 257px;
            width: 100%;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 16px;
            cursor: pointer;
          }
        }
      }
    }

    .our-values-container {
      padding-inline: 80px;
    }

    .our-values {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-inline: 80px;
      padding-block: 50px;
      width: 100%;
      // max-width: 1200px;
      // height: 252px;
      background: url("/public/images/our-values.webp");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 24px;
      color: #fff;
      gap: 15px;
      // margin: auto;

      .heading1 {
        color: $primary_color;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 1.2rem;
      }

      .heading3 {
        font-size: 1.2rem;
        max-width: 700px;
        text-align: center;
        width: 100%;
      }
    }

    .our-goals {

      // margin: auto;
      // max-width: 1200px;
      .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 32px;
        padding-inline: 80px;

        .our-goal-image {
          max-width: 650px;
          width: 100%;
          border-radius: 24px;

          img {
            max-width: 100%;
            height: auto;
            border-radius: 24px;
          }
        }

        .our-goal-text {
          display: flex;
          flex-direction: column;
          gap: 24px;
          width: 100%;

          .goal {
            color: $primary_color;
            font-weight: 700;
            font-size: 1.2rem;
          }

          .heading3 {
            max-width: 560px;
            width: 100%;
            font-weight: 700;
            font-size: 1.2rem;
          }

          .paragraph {
            max-width: 560px;
            width: 100%;
            color: #4f4f4f;
            font-size: 1rem;
          }

          .goals {
            display: flex;
            flex-direction: column;
            gap: 24px;
            max-width: 570px;
            width: 100%;
            border-radius: 16px;
            padding: 24px;
            background-color: #fcf8ff;

            .heading4 {
              max-width: 100%;
              color: $primary_color;
              font-weight: 700;
              font-size: 1rem;
            }

            .paragraph {
              max-width: 527px;
              width: 100%;
              color: #4f4f4f;
            }
          }
        }
      }
    }

    .our-impact {
      // max-width: 1200px;
      width: 100%;
      // margin: auto;
      height: 100%;

      .container {
        background-color: #ffffff;
        width: 100%;
        padding-block: 100px;

        // padding-inline: 80px;
        .impacts {
          display: flex;
          gap: 24px;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: 100%;

          .impact-text {
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 50%;
            // background-color: #FCF8FF;

            .title {
              max-width: 150px;
              width: 100%;
              height: 24px;
            }

            .heading2 {
              max-width: 431px;
              width: 100%;
              height: 90px;
            }

            .paragraph {
              max-width: 530px;
              width: 100%;
              color: #3f3f3f;
            }

            .impact-text-value {
              display: flex;
              gap: 16px;

              .value {
                display: flex;
                align-items: center;
                gap: 16px;
                width: 100%;
                // max-width: 255px;
                height: 80px;
                border-radius: 12px;
                padding-inline: 14px;
                background-color: #fbf8fe;
                text-align: start;

                .icon {
                  max-width: 46px;
                  width: 100%;
                  height: 46px;
                  width: 100%;
                  background-color: #fff;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 8px;
                }

                .value-text-number {
                  max-width: 165px;
                  width: 100%;
                  height: 53px;
                  display: flex;
                  flex-direction: column;
                  gap: 8px;

                  .numbers {
                    font-weight: 700;
                    font-size: 1.2rem;
                  }

                  .paragraph {
                    max-width: 165px;
                    width: 100%;
                    height: 40px;
                    color: #636363;
                  }
                }
              }
            }
          }

          .impact-image {
            display: flex;
            align-items: center;
            height: 100%;
            max-width: 700px;
            width: 50%;

            position: relative;
            z-index: 0;

            // .impact-back-img {
            // }

            .impact-big-img {
              max-width: 530px;
              width: 100%;

              border-radius: 12px;

              padding-left: 100px;
              // left: 175px;

              img {
                width: 100%;
                height: auto;
                border-radius: 12px;
              }
            }

            .impact-small-img {
              max-width: 200px;
              width: 100%;

              border-radius: 12px;
              position: absolute;
              z-index: 2;

              img {
                width: 100%;
                height: auto;
                border-radius: 12px;
              }
            }
          }
        }
      }
    }

    .our-events {
      // max-width: 1200px;
      //   margin: auto;

      .container {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding-inline: 80px;

        .headind4 {
          color: #9b51e0;
          text-transform: uppercase;
          font-weight: 700;
          font-size: 1.2rem;
        }

        .our-program-title {
          display: flex;
          gap: 24px;
          justify-content: space-between;
          align-items: center;

          .heading1 {
            font-family: "Quicksand", sans-serif;
            font-size: 1.5rem;
            font-weight: 700;

            span {
              font-weight: 300;
            }
          }

          .button {
            display: flex;
            align-items: center;
            gap: 10px;
            color: #9b51e0;
            text-transform: uppercase;
          }
        }

        .events-image-text {
          display: flex;
          justify-content: space-between;
          height: 100%;
          gap: 50px;

          .first-row {
            display: flex;
            flex-direction: column;
            gap: 24px;
          }

          .second-row {
            display: flex;
            flex-direction: column;
            gap: 24px;
            flex: 1;
            gap: 24px;

            .event-card-container {
              flex: 1;
              display: flex;
              flex-direction: column;
            }
          }

          .event-image {
            max-width: 586px;
            width: 100%;
            height: 270px;
            overflow: hidden;
            border-radius: 10px;

            img {
              max-width: 100%;
              height: auto;
              border-radius: 10px;
              object-fit: cover;
            }
          }
        }
      }
    }

    .shops {

      // max-width: 1200px;
      // margin: auto;
      .container {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .heading4-shop {
          max-width: 180px;
          width: 100%;
          height: 24px;
          background-color: #e1dde6;
        }

        .heading1-shop {
          max-width: 607px;
          width: 100%;
          height: 50px;
          background-color: #e1dde6;
        }

        .our-program-title {
          display: flex;
          gap: 24px;
          justify-content: space-between;
          align-items: center;

          .heading1 {
            color: #8b8b8b;
            max-width: 688px;
            width: 100%;
          }

          .paragraph {
            max-width: 645px;
            width: 100%;
            height: 48px;
            background-color: #e1dde6;
          }

          .button {
            color: $primary_color;
            text-transform: uppercase;
            font-weight: 600;
            display: flex;
            align-items: center;
            gap: 10px;
          }
        }
      }
    }

    .contact-us {

      // max-width: 1200px;
      // margin: auto;
      .container {
        width: 100%;
        display: flex;
        gap: 24px;
        justify-content: space-between;

        .contact-us-text {
          display: flex;
          flex-direction: column;
          gap: 32px;

          .headind4 {
            max-width: 180px;
            width: 100%;
            height: 24px;
            background-color: #e1dde6;
          }

          .our-program-title {
            display: flex;
            flex-direction: column;
            gap: 24px;

            .heading1 {
              max-width: 539px;
              width: 100%;
              height: 50px;
              background-color: #e1dde6;
            }

            .paragraph {
              max-width: 473px;
              width: 100%;
              color: #9c9c9c;
            }
          }
        }
      }
    }

    button,
    .button {
      cursor: pointer;
    }
  }

  .our-programs {
    background-color: #fcf8ff;
    width: 100%;

    .container {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .headind4 {
        color: #9b51e0;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.2rem;
      }

      .our-program-title {
        display: flex;
        gap: 24px;
        justify-content: space-between;
        align-items: center;

        .heading1 {
          font-family: "Quicksand", sans-serif;
          font-weight: 700;
          font-size: 1.5rem;

          span {
            font-weight: 300;
          }
        }

        .button {
          display: flex;
          align-items: center;
          gap: 10px;
          color: #9b51e0;
          text-transform: uppercase;
          cursor: pointer;
        }
      }

      .programs {
        display: flex;
        flex-wrap: wrap;
        gap: 32px;

        .card {
          flex: 1 1 300px !important;
        }

        .program {
          max-width: 415px;
          width: 100%;
          height: 600px;
          background-color: #fbfbfb;
          border-radius: 24px;
          padding: 24px;
          display: flex;
          flex-direction: column;
          gap: 20px;

          .image-button-text {
            max-width: 365px;
            width: 100%;
            height: 220px;
            background-color: #e1dde6;
            border-radius: 8px;
            position: relative;

            .image {
              max-width: 365px;
              width: 100%;
              height: 220px;
              background-color: #e1dde6;
              border-radius: 8px;
              position: relative;
              z-index: 100;
            }

            .button {
              max-width: 135px;
              width: 100%;
              height: 42px;
              background-color: #b4b0b9;
              border-radius: 8px;
              padding: 8px 16px;
              position: absolute;
              z-index: 150;
              top: 20px;
              right: 15px;
            }
          }

          .paragraph {
            width: 100%;
            max-width: 365px;
            height: 65px;
            background-color: #e1dde6;
          }

          .goals-raised {
            display: flex;
            align-items: center;
            max-width: 365px;
            width: 100%;
            height: 60px;
            background-color: #e1dde6;
            padding: 16px 24px;
            gap: 16px;

            .goal-number {
              max-width: 150px;
              width: 100%;
              height: 30px;
              background-color: #b4b0b9;
              border-radius: 8px;
            }

            .goal-raised {
              max-width: 150px;
              width: 100%;
              height: 30px;
              background-color: #b4b0b9;
              border-radius: 8px;
            }
          }

          .progrssive-bar {
            display: flex;
            gap: 12px;
            align-items: center;
            width: 100%;
            max-width: 365px;
            background-color: #e1dde6;
            height: 30px;
            padding: 8px 12px;

            .progress-percentage {
              max-width: 20px;
              width: 100%;
              height: 15px;
              background-color: #b4b0b9;
            }

            .progress-bar {
              max-width: 330px;
              width: 100%;
              height: 15px;
              background-color: #b4b0b9;
              border-radius: 8px;
            }
          }

          .progress-button {
            max-width: 365px;
            width: 100%;
            height: 50px;
            background-color: #b4b0b9;
            border-radius: 26px;
            padding: 8px 16px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1060px) {
  body {
    font-size: 18px;
  }

  .contact-forms {
    width: 100%;
  }

  .charity-home .body-section .our-events .container .events-image-text .event-image {
    max-width: none;
  }

  .charity-home .body-section .our-goals .container .our-goal-text {
    align-items: center;
    text-align: center;

    .goal {
      text-transform: uppercase;
    }

    .goals {
      display: flex;
      flex-direction: column;
      align-items: center;

      .heading4 {
        text-transform: uppercase;
      }
    }
  }

  .charity-home .hero-section .container .hero-text .button {
    font-size: 0.9rem;
  }

  .charity-home .body-section .our-goals .container .our-goal-image {
    max-width: 600px;
    width: 100%;
  }

  .charity-home {
    .hero-section {
      padding-inline: 10px;

      .general-inquirie {
        justify-content: center;
      }

      .inquiries-info {
        display: flex;
        flex-direction: column;
        // max-width: 344px;
        width: 100%;
        right: 0;
        justify-content: center;
        align-items: center;

        .info-inquirie {
          max-width: 350px;
          width: 100%;
        }

        .divider {
          height: 1px;
          // width: 210px;
          width: 100%;
        }
      }
    }

    .shops,
    .our-events,
    .faqs {
      .container {
        // padding-inline: 80px;

        .faq-questions {
          grid-template-columns: 1fr;
        }
      }
    }

    .stories {
      flex-wrap: wrap;
    }

    .shops-images-texts {
      flex-wrap: wrap;
    }

    .about-us,
    .our-goals,
    .contact-us {
      .container {
        flex-wrap: wrap;
        // padding-inline: 80px;

        .contact-us-text {
          width: 100%;
        }
      }
    }

    .charity-home .body-section .about-us .container {
      flex-direction: column;
    }

    .about-us,
    .our-goals {
      .container {
        flex-direction: column-reverse;
      }
    }

    .our-impact {
      .container {
        .impacts {
          flex-direction: column;
          // padding-inline: 80px;

          .impact-image {
            width: 100%;
          }
        }
      }
    }

    .our-programs {
      .container {
        .programs {
          flex-wrap: wrap;
        }
      }
    }

    .our-events {
      .container {
        .events-image-text {
          flex-wrap: wrap;
        }
      }
    }
  }

  .charity-home .body-section .shops .container .our-program-title,
  .charity-home .faqs .container .our-program-title {
    flex-direction: column;
    align-items: start;
  }

  .charity-home .body-section .our-values .heading3 {
    font-size: 1rem;
  }

  .charity-home .body-section .about-us .container .about-us-text,
  .charity-home .hero-section .container .hero-text,
  .charity-home .body-section .our-impact .container .impacts .impact-text,
  .charity-home .body-section .shops .container {
    text-align: center;
    align-items: center;

    .button {
      padding: 20px 32px;
      font-size: 0.9rem;
    }
  }

  .charity-home .body-section .our-impact .container .impacts {
    gap: 50px;
  }

  .charity-home .body-section .our-impact .container .impacts .impact-text .impact-text-value {
    flex-wrap: wrap;

    .value {
      justify-content: center;
    }
  }

  .charity-home .body-section .about-us .container .about-us-images {
    max-width: 400px;
    width: 100%;
  }

  .charity-home .body-section .contact-us .container .contact-us-text .our-program-title .paragraph {
    max-width: 100%;
  }

  .charity-home .body-section .our-events .container .events-image-text {
    gap: 24px;
  }

  .charity-home .body-section .our-impact .container .impacts .impact-text {
    width: 100%;
  }

  .charity-home .body-section .our-impact .container .impacts .impact-image {
    width: 100%;
  }

  .charity-home .body-section .about-us .container,
  .charity-home .body-section .our-values-container,
  .charity-home .body-section .our-goals .container,
  .charity-home .body-section .our-events .container,
  .charity-home .body-section .our-impact .container,
  .charity-home .our-stories .container .stories,
  .charity-home .faqs {
    padding-inline: 20px;
    width: 100%;
  }

  .charity-home .body-section .our-impact .container .impacts .impact-text .impact-text-value .value .value-text-number .paragraph {
    font-size: 1rem;
  }

  .charity-home .body-section .our-events .container .our-program-title .heading1 {
    font-size: 1.3rem;
  }
}

@media screen and (min-width: 1500px) {

  .charity-home .body-section .about-us .container,
  .charity-home .body-section .our-goals .container,
  .charity-home .body-section .our-impact .container,
  .charity-home .body-section .contact-us .container,
  .charity-home .faqs .container,
  .charity-home .body-section .shops .container,
  .charity-home .our-stories .container,
  .body-section .our-events .container,
  .charity-home .our-programs .container,
  .charity-home .body-section .our-values-container {
    max-width: 1500px;
    margin: auto;
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .container {
    padding-inline: 20px;
    width: 100%;
    max-width: 100%;
    padding-block: 20px;
  }

  .events-image-text .events {
    display: flex;
    flex-direction: column;
    gap: 10rem;

    .event {
      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 1rem;
      }

      .event-content {
        top: 6rem;

        h3,
        p,
        .date-location,
        .location {
          width: 100%;
          font-size: 0.9rem;
        }

        .event-buttons {
          gap: 0.5rem;

          button,
          .button {
            padding: 1rem 1rem;
            font-size: 0.8rem;
          }
        }
      }
    }
  }

  .charity-home .body-section .our-events .container {
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 1337px) {
  .events-image-text {
    flex-direction: column;

    .first-row,
    .second-row {
      width: 100%;
    }

    .event-image {
      max-width: 100%;
    }

    .event-content {

      h3,
      p,
      .date-location,
      .location {
        width: 96%;
        font-size: 0.9rem;
      }

      .event-buttons {
        gap: 0.5rem;

        button,
        .button {
          padding: 1rem 1rem;
          font-size: 0.8rem;
        }
      }
    }
  }

  .charity-home .body-section .our-events .container .events-image-text .event-image {
    max-width: none;
    // height: 400px;
  }

  .event-card-container {
    max-width: none;
  }

  .charity-home .body-section .our-events .container .events-image-text {
    gap: 24px;
  }
}

@media screen and (max-width: 1179px) {
  .event-content {

    h3,
    p,
    .date-location,
    .location {
      width: 90%;
      font-size: 0.9rem;
    }

    .event-buttons {
      gap: 0.5rem;

      button,
      .button {
        padding: 1rem 1rem;
        font-size: 0.8rem;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .charity-home .our-programs .container {
    align-items: center;

    .our-program-title {
      flex-direction: column;
      align-items: center;

      .button {
        display: none;
      }
    }
  }

  .charity-home .our-programs .container .our-program-title .button {
    display: none;
  }

  .charity-home .body-section .our-impact .container {
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .stories-card-container .stories-card {
    flex-direction: column;
    text-align: center;
  }

  .charity-home .body-section .shops .container {
    padding-bottom: 20px;
  }

  .charity-home .body-section .contact-us .container {
    padding-bottom: 20px;
  }

  .charity-home .faqs .container {
    padding-bottom: 40px;
  }

  .charity-home .body-section .our-events .container {
    padding-bottom: 180px;
  }

  .faqs .container {
    padding-inline: 0px;
  }

  .container {
    height: fit-content;
  }

  .events-image-text .events {
    display: flex;
    flex-direction: column;
    gap: 14rem;

    .event {
      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 1rem;
      }

      .event-content {
        top: 7rem;
      }

      .event-buttons {
        gap: 0.5rem;

        button,
        .button {
          padding: 1rem 1rem;
          font-size: 0.8rem;
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .events-image-text .events {
    display: flex;
    flex-direction: column;
    gap: 14rem;

    .event {
      img {
        width: 100%;
        height: 170px;
        object-fit: cover;
        border-radius: 1rem;
      }

      .event-content {
        top: 6rem;
        left: 0.6rem;
        right: 0.6rem;
        padding: 1.2rem;

        h3,
        p,
        .date-location,
        .location {
          width: 94%;
          font-size: 0.9rem;
        }

        .event-buttons {
          gap: 0.5rem;
          display: flex;
          flex-direction: column;

          button,
          .button {
            width: 100%;
            padding: 0.8rem 1rem;
            font-size: 0.8rem;
          }
        }
      }
    }
  }

  .charity-home .body-section .our-impact .container .impacts .impact-image .impact-big-img {
    padding-left: 100px;
    max-width: 650px;
    width: 100%;
    height: 200px;
  }

  .charity-home .body-section .our-impact .container .impacts .impact-image .impact-small-img {
    max-width: 170px;
    width: 100%;
    border-radius: 12px;
    position: absolute;
    z-index: 2;
    height: 150px;

    img {
      width: 100%;
      height: auto;
      border-radius: 12px;
    }
  }

  .charity-home .body-section .our-values {
    padding-inline: 30px;
  }

  .body-section .container .our-impact {
    .impact-image {
      display: flex;
      align-items: center;
      height: 100%;
      max-width: 700px;
      width: 50%;
      position: relative;
      z-index: 0;
    }
  }
}

/* @media screen and (max-width: 380px) {
  .charity-home
    .body-section
    .our-impact
    .container
    .impacts
    .impact-image
    .impact-big-img {
    padding-left: 100px;
    max-width: 650px;
    width: 100%;
    height: 190px;
  }
  .charity-home
    .body-section
    .our-impact
    .container
    .impacts
    .impact-image
    .impact-small-img {
    max-width: 140px;
    width: 100%;
    border-radius: 12px;
    position: absolute;
    z-index: 2;
    img {
      width: 100%;
      height: auto;
      border-radius: 12px;
    }
  }

}
 */

.events {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;

  .event {
    flex: 1 1 300px;
    position: relative;
    // height: 300px;

    img {
      width: 100%;
      height: 400px;
      object-fit: cover;
      border-radius: 1rem;
    }

    .event-content {
      position: absolute;
      top: 10rem;
      left: 2rem;
      right: 2rem;
      background-color: rgb(245, 245, 245);
      padding: 2rem;
      border-radius: 1rem;
      display: grid;
      height: fit-content;
      gap: 1rem;

      .date,
      .location {
        display: flex;
        align-items: center;
        gap: 1rem;

        small {
          color: gray;
        }
      }
    }
  }
}
.event-registration-form {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
  max-width: 600px;
  margin: auto;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.buttons .cancel-btn {
  background-color: whitesmoke;
  color: gray;
}

form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
form input,
form select,
form textarea {
  width: 100%;
  outline: none;
  border: 1px solid #EBEBEB;
  border-radius: 24px;
  padding: 16px 24px;
  resize: none;
}
form input ::-moz-placeholder, form select ::-moz-placeholder, form textarea ::-moz-placeholder {
  color: #919393;
}
form input ::placeholder,
form select ::placeholder,
form textarea ::placeholder {
  color: #919393;
}
form .form-row {
  display: flex;
  gap: 15px;
}
form .form-row input,
form .form-row select {
  flex: 1;
}
form textarea {
  min-height: 100px;
}
form .form-actions {
  display: flex;
  margin-top: 20px;
  gap: 16px;
}
form .form-actions button {
  padding: 16px 24px;
  border: none;
  flex: 1;
  border-radius: 24px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}
form .form-actions button.cancel-btn {
  background-color: #EBEBEB;
  color: #919393;
}
form .form-actions button.cancel-btn:hover {
  background-color: #e0e0e0;
}
form .form-actions button.submit-btn {
  background-color: #9B51E0;
  color: #fff;
}
form .form-actions button.submit-btn:hover {
  background-color: #87008C;
}

@media screen and (max-width: 540px) {
  .form-row {
    flex-direction: column;
  }
}
.registration-form-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}/*# sourceMappingURL=eventRegistration.css.map */
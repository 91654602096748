@import '../variables/variables.scss';

.stories-card-container{
    padding: 20px;
    border-radius: 10px;
    background-color: #8B4BC7;
    font-family: $font_family;
    font-size: 1.2vw;
    max-width: 600px;
    border-radius: 16px;
    color: #fff;
    display: flex;
    align-items: center;
    margin: auto;

    .stories-card{
        display: flex;
        gap: 24px;
        align-items: center;

        .img-container{
            max-width: 148px;
            width: 100%;
            height: 148px;
            border-radius: 12px;
            overflow: hidden;
            margin-right: 20px;

            img{
                width: 100%;
                height: 100%;
                border-radius: 12px;
                object-fit: cover;
            }

        }

        .story-details{
            font-size: 1rem;
            display: flex;
            flex-direction: column;
            gap: 8px;

            .name{
                font-weight: 700;
                font-size: 1.1rem;
            }

            .role{
                color: #BFBEBE
            }


        }
        
    }
}
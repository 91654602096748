@import "../variables/variables.scss";

.shops-images-texts {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;


  .product-card-container {
    height: 100%;
    width: 100%;
    max-width: 278px;

    &.special-card{
      .img-container{
        border: 1px solid #d9d9d9;
        border-radius: 10px 10px 0px 0px;
      }
    }
    .product-card {
      display: flex;
      flex-direction: column;

      width: 100%;
      height: 100%;
      border-radius: 10px 10px 0 0;

      .img-container {
        width: 100%;
        height: 200px;

        .product-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px 10px 0px 0px;
        }
      }

      .product-info {
        background-color: #fff;
        font-weight: 600;
        padding: 16px;
        border-radius: 0px 0px 10px 10px;
        border-width: 0px 1px 1px 1px;
        border-color: #d9d9d9;
        border-style: solid;
        height: 40%;

        .price {
          color: #87008c;
        }
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .shops-images-texts {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 900px) {
  .shops-images-texts {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 600px) {
  .shops-images-texts {
    grid-template-columns: repeat(1, 1fr);

    .product-card-container {
      max-width: 100%;

      .product-card {
        .img-container {
          height: 300px;
        }

        .product-info {
          padding-block: 30px;
        }
      }
    }
  }
}

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.container{
    padding-block: 50px;
}


.card {
  background-color: #FBFBFB;
  max-width: 415px;
  width: 100%;
  // height: 600px;
  border-radius: 24px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;


  .text {
      display: flex;
      flex-direction: column;
      gap: 12px;
  }

  .image-button {
      max-width: 365px;
      width: 100%;
      border-radius: 8px;
      position: relative;

      img {
          max-width: 365px;
          width: 100%;
          height: 220px;
          border-radius: 8px;
          position: relative;
          z-index: 100;
          object-fit: cover;

      }

      .white-btn {
          max-width: 135px;
          width: 100%;
          height: 42px;
          background-color: white;
          border-radius: 8px;
          padding: 8px 8px;
          position: absolute;
          z-index: 150;
          top: 20px;
          right: 15px;
          display: flex;
          align-items: center;
          gap: 10px;
          color: #9B51E0;

      }

      .icon {
          color: #9B51E0;
      }

  }

  a {
      text-decoration: none;
  }

  .donate-btn {
      width: fit-content;
      color: #9B51E0;
      padding: 12px 100px;
      font-size: 18px;
      background-color: white;
      border: none;
      border-radius: 32px;
      border: 1px solid rgba(155, 81, 224, 0.24);
      display: flex;
      align-items: center;
      gap: 12px;

      .icon {
          color: #9B51E0;
      }
  }

  .progress-bar {
      display: flex;
      gap: 10px;
      align-items: center;
      progress {
          border-radius: 7px; 
          width: 80%;
          height: 22px;
          margin-left: -11.5%;

        }
        progress::-webkit-progress-bar {
background-color: grey;
          border-radius: 7px;
        }
        progress::-webkit-progress-value {
background-color: #9B51E0;
          border-radius: 7px;

        }
       
  }

  .progress-bar-container {
      display: flex;

      align-items: center;
      margin-bottom: 15px;
      width: 100%;

      .percentage {
        font-size: 1rem;
        color: #666;
        min-width: 30px;
      }

      .progress-bar {
        height: 10px;
        background-color: #e0e0e0;
        border-radius: 5px;
        flex-grow: 1;
        overflow: hidden;

        .progress {
          height: 100%;
          background-color: #8e44ad;
          border-radius: 5px;
        }
      }
    }

}

.goal-raised {
  display: flex;
  gap: 24px;
  background-color: white;
  padding: 24px;
  border-radius: 8px;

  .goal {
      display: flex;
      gap: 12px;
      align-items: center;
      font-size: 16px;
      color: #717171;
  }

  .raised {
      display: flex;
      gap: 12px;
      align-items: center;
      font-size: 16px;
      color: #717171;
  }
}

@media screen and (max-width: 1352px) {
  .card {
    .donate-btn {

        width: fit-content;
        color: #9B51E0;
        padding: 12px 80px;
    }
}
}

@media screen and (max-width:900px){
  .card{
    max-width: 100%;
    .image-button{
    max-width: 100%;
    img{
    max-width: 100%;
    }
    }
    .donate-btn{
    width: 100%;
    }
       }

       .card .image-button .white-btn{
        font-size: 1rem;
       }
}
.single-page {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.single-page .program-details {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-inline: 80px;
  padding-top: 140px;
}
.single-page .program-details .programs {
  background-color: #F9F3FF;
  border-radius: 8px;
  padding: 48px;
}
.single-page .program-details .programs .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
.single-page .program-details .programs .text p {
  color: #717171;
}
.single-page .program-details .programs .text h3 {
  font-size: 32px;
}
.single-page .program-details .programs .text span {
  color: #9B51E0;
}
.single-page .program-details .programs .text p {
  font-weight: bolder;
}
.single-page .program-details a {
  text-decoration: none;
}
.single-page .program-details .program-details-layout {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}
.single-page .program-details .program-details-layout .image img {
  width: 600px;
  height: 400px;
  border-radius: 8px;
  -o-object-fit: cover;
     object-fit: cover;
}
.single-page .program-details .program-details-layout .text {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.single-page .program-details .program-details-layout .text .progress-bar-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
}
.single-page .program-details .program-details-layout .text .progress-bar-container .percentage {
  font-size: 1vw;
  color: #666;
  min-width: 30px;
}
.single-page .program-details .program-details-layout .text .progress-bar-container .progress-bar {
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  flex-grow: 1;
  overflow: hidden;
}
.single-page .program-details .program-details-layout .text .progress-bar-container .progress-bar .progress {
  height: 100%;
  background-color: #8e44ad;
  border-radius: 5px;
}
.single-page .program-details .program-details-layout .text .goal-raised {
  display: flex;
  gap: 24px;
  background-color: #FBFBFB;
  padding: 24px;
  border-radius: 8px;
}
.single-page .program-details .program-details-layout .text .goal-raised .goal {
  display: flex;
  gap: 12px;
  align-items: center;
  font-size: 16px;
  color: #717171;
}
.single-page .program-details .program-details-layout .text .goal-raised .raised {
  display: flex;
  gap: 12px;
  align-items: center;
  font-size: 16px;
  color: #717171;
}
.single-page .program-details .program-details-layout .text .donors-donated {
  display: flex;
  gap: 24px;
}
.single-page .program-details .program-details-layout .text .donors-donated .donors-card {
  background-color: #FBFBFB;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;
  padding: 12px 48px;
}
.single-page .program-details .program-details-layout .text .donors-donated .donated-card {
  background-color: #FBFBFB;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;
  padding: 12px 48px;
}
.single-page .program-details .program-details-layout .text .progress-bar {
  display: flex;
  gap: 10px;
  align-items: center;
}
.single-page .program-details .program-details-layout .text .progress-bar progress {
  width: 100%;
  border-radius: 10px;
}
.single-page .program-details .program-details-layout .text .donate-btn {
  width: -moz-fit-content;
  width: fit-content;
  color: #9B51E0;
  padding: 12px 100px;
  width: 100%;
  font-size: 18px;
  background-color: white;
  border: none;
  border-radius: 32px;
  border: 1px solid rgba(155, 81, 224, 0.24);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
.single-page .program-details .program-details-layout .text .donate-btn .icon {
  color: #9B51E0;
}

@media screen and (max-width: 800px) {
  .single-page .program-details {
    padding-inline: 20px;
  }
  .single-page .program-details .program-details-layout {
    grid-template-columns: 1fr;
  }
  .single-page .program-details .program-details-layout .image img {
    width: 100%;
    height: auto;
  }
}/*# sourceMappingURL=program-details.css.map */
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");
.contact-card-container {
  font-family: "Outfit", sans-serif;
}
.contact-card-container .contact-card form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}
.contact-card-container .contact-card form .email-and-tel {
  display: flex;
  gap: 20px;
  min-width: 100%;
}
.contact-card-container .contact-card form input, .contact-card-container .contact-card form textarea {
  padding: 18px 16px;
  border-radius: 10px;
  border: 1px solid #E6E6E6;
  outline: none;
  flex-grow: 1;
  display: flex;
  align-items: center;
  font-family: "Outfit", sans-serif;
  font-size: 14px;
  resize: none;
}
.contact-card-container .contact-card form input::-moz-placeholder, .contact-card-container .contact-card form textarea::-moz-placeholder {
  color: #C0C0C0;
  font-family: "Outfit", sans-serif;
}
.contact-card-container .contact-card form input::placeholder, .contact-card-container .contact-card form textarea::placeholder {
  color: #C0C0C0;
  font-family: "Outfit", sans-serif;
}
.contact-card-container .contact-card form textarea {
  min-height: 196px;
}
.contact-card-container .contact-card form button {
  color: #fff;
  background-color: #9B51E0;
  border-radius: 32px;
  border: none;
  font-family: "Outfit", sans-serif;
  font-size: 1rem;
  flex-shrink: 0;
}

@media screen and (max-width: 900px) {
  .contact-card-container .contact-card form input, .contact-card-container .contact-card form textarea {
    font-size: 1rem;
  }
}
@media screen and (max-width: 560px) {
  /* .contact-card-container .contact-card form .email-and-tel{
      flex-direction: column;
  } */
  .contact-card-container .contact-card form {
    max-width: 100%;
  }
  .contact-card-container .contact-card form .email-and-tel {
    flex-wrap: wrap;
    gap: 12px;
  }
}/*# sourceMappingURL=contact.css.map */
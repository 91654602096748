.program-page {
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.program-page .program-layout {
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding-inline: 80px;
}
.program-page .program-layout .programs {
  background-color: #f9f3ff;
  border-radius: 8px;
  padding: 48px;
}
.program-page .program-layout .programs .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
.program-page .program-layout .programs .text p {
  color: #717171;
}
.program-page .program-layout .programs .text h3 {
  font-size: 32px;
}
.program-page .program-layout .programs .text span {
  color: #9b51e0;
}
.program-page .program-layout .programs .text p {
  font-weight: bolder;
}
.program-page .program-layout .program-cards .cards {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  gap: 24px;
}
.program-page .program-layout .program-cards .cards .card {
  background-color: #fbfbfb;
  max-width: 415px;
  width: 100%;
  border-radius: 24px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.program-page .program-layout .program-cards .cards .card .text {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.program-page .program-layout .program-cards .cards .card .image-button {
  max-width: 365px;
  width: 100%;
  border-radius: 8px;
  position: relative;
}
.program-page .program-layout .program-cards .cards .card .image-button img {
  max-width: 365px;
  width: 100%;
  height: 220px;
  border-radius: 8px;
  position: relative;
  z-index: 100;
  -o-object-fit: cover;
     object-fit: cover;
}
.program-page .program-layout .program-cards .cards .card .image-button .white-btn {
  max-width: 135px;
  width: 100%;
  height: 42px;
  background-color: white;
  border-radius: 8px;
  padding: 8px 8px;
  position: absolute;
  z-index: 150;
  top: 20px;
  right: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #9b51e0;
  cursor: pointer;
}
.program-page .program-layout .program-cards .cards .card .image-button .icon {
  color: #9b51e0;
}
.program-page .program-layout .program-cards .cards .card a {
  text-decoration: none;
}
.program-page .program-layout .program-cards .cards .card .donate-btn {
  width: -moz-fit-content;
  width: fit-content;
  color: #9b51e0;
  padding: 12px 100px;
  font-size: 18px;
  background-color: white;
  border: none;
  border-radius: 32px;
  border: 1px solid rgba(155, 81, 224, 0.24);
  display: flex;
  align-items: center;
  gap: 12px;
}
.program-page .program-layout .program-cards .cards .card .donate-btn .icon {
  color: #9b51e0;
}
.program-page .program-layout .program-cards .cards .card .progress-bar {
  display: flex;
  gap: 10px;
  align-items: center;
}
.program-page .program-layout .program-cards .cards .card .progress-bar progress {
  border-radius: 7px;
  width: 80%;
  height: 22px;
  margin-left: -11.5%;
}
.program-page .program-layout .program-cards .cards .card .progress-bar progress::-webkit-progress-bar {
  background-color: grey;
  border-radius: 7px;
}
.program-page .program-layout .program-cards .cards .card .progress-bar progress::-webkit-progress-value {
  background-color: #9b51e0;
  border-radius: 7px;
}
.program-page .program-layout .program-cards .cards .card .progress-bar-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
}
.program-page .program-layout .program-cards .cards .card .progress-bar-container .percentage {
  font-size: 1vw;
  color: #666;
  min-width: 30px;
}
.program-page .program-layout .program-cards .cards .card .progress-bar-container .progress-bar {
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  flex-grow: 1;
  overflow: hidden;
}
.program-page .program-layout .program-cards .cards .card .progress-bar-container .progress-bar .progress {
  height: 100%;
  background-color: #8e44ad;
  border-radius: 5px;
}
.program-page .program-layout .program-cards .cards .goal-raised {
  display: flex;
  gap: 24px;
  background-color: white;
  padding: 24px;
  border-radius: 8px;
}
.program-page .program-layout .program-cards .cards .goal-raised .goal {
  display: flex;
  gap: 12px;
  align-items: center;
  font-size: 16px;
  color: #717171;
}
.program-page .program-layout .program-cards .cards .goal-raised .raised {
  display: flex;
  gap: 12px;
  align-items: center;
  font-size: 16px;
  color: #717171;
}

@media screen and (max-width: 1352px) {
  .program-page .program-layout .program-cards .cards .card .donate-btn {
    width: -moz-fit-content;
    width: fit-content;
    color: #9b51e0;
    padding: 12px 80px;
  }
}
@media screen and (max-width: 1040px) {
  .program-page .program-layout .program-cards .cards {
    grid-template-columns: 50% 50%;
  }
}
@media screen and (max-width: 846px) {
  .program-page .program-layout {
    padding-inline: 20px;
  }
  .program-page .program-layout .programs .text {
    gap: 20px;
  }
  .program-page .program-layout .programs .text p {
    color: #717171;
    font-size: 17px;
    font-weight: 200;
  }
  .program-page .program-layout .programs .text h3 {
    font-size: 28px;
  }
  .program-page .program-layout .programs .text span {
    color: #9b51e0;
  }
  .program-page .program-layout .programs .text p {
    font-weight: bolder;
  }
  .program-page .program-layout .program-cards .cards {
    grid-template-columns: 100%;
  }
  .program-page .program-layout .program-cards .cards .card {
    max-width: 100%;
  }
  .program-page .program-layout .program-cards .cards .card .image-button {
    max-width: 100%;
  }
  .program-page .program-layout .program-cards .cards .card .image-button img {
    max-width: 100%;
  }
  .program-page .program-layout .program-cards .cards .card .donate-btn {
    width: 100%;
  }
}/*# sourceMappingURL=programs.css.map */
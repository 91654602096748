@import "../variable/variable.scss";

.sub{
  margin-top: 150px;
 
  flex-direction: column;
  background-color: $primary-color;
  padding-inline: 60px;
  padding-block: 80px;
  padding-top: 20px;
}

  .footer-main{
    width: 100%;
   
   
    display: flex;
    gap: 80px;
    justify-content: center;
   
    .img-text{
      background-image: url("../images/footer-img.png");
      background-repeat: no-repeat;
      width: 556px;
      height: 296px;
      display: flex;
      padding-left: 40px;
      padding-right: 20px;
      padding-top: 40px;
      border-radius: 16px;
     
      .location-call{
        display: flex;
        flex-direction: column;
        gap: 30px;
      }
      .icon-text{
        display: flex;
        gap: 20px;
        color: white;
      p{
        font-size: 14px;
        font-weight: 500;
      }
        h2{
          font-size: 16px;
          font-weight: 700;
        }

        .email{
          font-size: 20px;
          font-weight: 500;
        }

        .actual-text{
          display: flex;
          gap: 5px;
          flex-direction: column;
        }

        .icon-footer{
          background-color: white;
          padding: 10px;
          padding-inline: 12px;
          justify-content: center;
          align-items: center;
          height: fit-content;
          border-radius: 12px;

        
        }
      }
    }
    .useful-links{
      padding-top: 30px;
      display: flex;
      flex-direction: column;
      gap: 30px;
      h1{
        font-size: 20px;
        font-weight: 500;
        color: white;
      }
     .links-text{
      display: grid;
      grid-template-columns: 1fr 1fr;
     gap: 20px;
     text-wrap: nowrap;
    

      .left-links, .right-links{
        display: flex;
        gap: 15px;
        flex-direction: column;

        a{
          text-decoration: none;
          font-size: 18px;
          color: white;
        }
      }
     }
    }

    .programs-footer{
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      h1{
        font-size: 20px;
        font-weight: 500;
        color: white;
      }
      .white-part{
        .value {
          display: flex;
          align-items: center;
          gap: 16px;
          width: 100%;
          // max-width: 255px;
          height: 80px;
          border-radius: 12px;
          padding-inline: 14px;
          background-color: #fbf8fe;

          .image-div {
            max-width: 46px;
            width: 100%;
            height: 46px;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            background: linear-gradient(
        89.96deg,
        rgba(0, 0, 0, 0.58) 0.04%,
        rgba(0, 0, 0, 0) 99.97%
      ),
      url("/public/images/big-image.webp");
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
          }

          .program-title {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 8px;

            .title {
              width: 100%;
              font-weight: 700;
              cursor: pointer;
            }

            .title:hover{
              color: #C0C0C0;
            }

          }
        }
      }
    }
  }

  @media (max-width: 1160px) {
    .footer-main{
      gap: 40px;
    }
  }

  
  @media (max-width: 1100px) {
    .footer-main{
      display: grid;
      grid-template-columns: 60% 30%;
      .img-text{
        width: 100%;
      }
     
    }
  }

  @media (max-width: 850px) {
    .footer-main{
     .useful-links{
      .links-text{
        grid-template-columns: 50% 60%;
      }
     }
     
    }
  }

  @media (max-width: 780px) {
    .footer-main{
     display: flex;
     flex-direction: column;

     .useful-links{
      .links-text{
        grid-template-columns: 20% 20%;
        text-wrap: nowrap;
      }
     }
     
    }
  }

  @media (max-width: 580px) {
    .sub{
      padding-inline: 0px;
    }

    .footer-main{
      .useful-links{
        padding-left: 20px;
      }

      .programs-footer{
        padding-inline: 20px;

        .white-part{
          width: 100%;
        }
      }
    }

    .footer-main .useful-links .links-text{
      gap: 40px;
    }
  }

  @media (max-width: 380px) {
    .footer-main{
      .img-text{
        padding-left: 15px;
      }
    }
   
  }

.single-page{
display: flex;
flex-direction: column;
gap: 24px;
.program-details{
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-inline: 80px;
padding-top: 140px;
    .programs {
        background-color: #F9F3FF;
        border-radius: 8px;
        padding: 48px;
       


        .text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 12px;

            

            p {
                color: #717171;
            }

            h3 {
                font-size: 32px;
            }

            span {
                color: #9B51E0;
            }

            p {
                font-weight: bolder;
            }


        }
    }
    a {
        text-decoration: none;
    }
    
    .program-details-layout{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    .image{
    img{
        width: 600px;
        height: 400px;
    border-radius: 8px;
    object-fit: cover;
    }
    }
.text{
display: flex;
flex-direction: column;
gap: 24px;

.progress-bar-container {
    display: flex;

    align-items: center;
    margin-bottom: 15px;
    width: 100%;

    .percentage {
      font-size: 1vw;
      color: #666;
      min-width: 30px;
    }

    .progress-bar {
      height: 10px;
      background-color: #e0e0e0;
      border-radius: 5px;
      flex-grow: 1;
      overflow: hidden;

      .progress {
        height: 100%;
        background-color: #8e44ad;
        border-radius: 5px;
      }
    }
  }
.goal-raised {
    display: flex;
    gap: 24px;
background-color: #FBFBFB;
    padding: 24px;
    border-radius: 8px;

    .goal {
        display: flex;
        gap: 12px;
        align-items: center;
        font-size: 16px;
        color: #717171;
    }

    .raised {
        display: flex;
        gap: 12px;
        align-items: center;
        font-size: 16px;
        color: #717171;
    }
}
.donors-donated{
display: flex;
gap: 24px;

.donors-card{
background-color: #FBFBFB;
display: flex;
flex-direction: column;
gap: 8px;
border-radius: 8px;
padding: 12px 48px;
}
.donated-card{
background-color: #FBFBFB;
display: flex;
flex-direction: column;
gap: 8px;
border-radius: 8px;
padding: 12px 48px;
}
}
.progress-bar {
    display: flex;
    gap: 10px;
    align-items: center;

    progress {
        width: 100%;

        border-radius: 10px;

    }
}
.donate-btn {
    width: fit-content;
    color: #9B51E0;
    padding: 12px 100px;
width: 100%;
    font-size: 18px;
    background-color: white;
    border: none;
    border-radius: 32px;
    border: 1px solid rgba(155, 81, 224, 0.24);
    display: flex;
    align-items: center;
justify-content: center;
    gap: 12px;

    .icon {
        color: #9B51E0;
    }
}
}
    }
    
    }
}
@media screen and (max-width: 800px) {
.single-page{
.program-details{
padding-inline: 20px;
.program-details-layout{
grid-template-columns: 1fr;
.image{
    img{
        width: 100%;
        height: auto;
    }
}
}
}
}
    
}
@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Outfit:wght@100..900&display=swap");
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Outfit", sans-serif;
}

button,
.button {
  width: -moz-fit-content;
  width: fit-content;
  color: white;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  background-color: #9B51E0;
  border: none;
  border-radius: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-wrap: nowrap;
}

.secondary-button {
  background-color: transparent;
  border: 1px solid #9B51E0;
  color: #9B51E0;
}

.event-buttons button,
.event-buttons .button {
  padding: 1rem 2rem;
  font-size: 0.8rem;
}

.success-message {
  background-color: rgba(0, 128, 0, 0.238);
  color: black;
  padding: 1rem;
  border-radius: 1rem;
}

.error-message {
  background-color: rgba(255, 99, 71, 0.361);
  color: black;
  padding: 1rem;
  border-radius: 1rem;
}

.loading-icon {
  animation: loading 1s infinite linear;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
}
.gallery img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.images-gallery {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
}

.videos {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
}
.videos video {
  width: 100%;
}

.mosaic-gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 250px;
  grid-auto-flow: dense;
  gap: 1rem;
  padding: 1rem;
}
.mosaic-gallery__item {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}
.mosaic-gallery__item--wide {
  grid-column: span 2;
}
.mosaic-gallery__item--tall {
  grid-row: span 2;
}
.mosaic-gallery__item--large {
  grid-column: span 2;
  grid-row: span 2;
}
.mosaic-gallery img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transition: all 0.5s ease;
}
.mosaic-gallery img:hover {
  transform: scale(2);
}

@media (max-width: 1200px) {
  .mosaic-gallery {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 768px) {
  .mosaic-gallery {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 480px) {
  .mosaic-gallery {
    grid-template-columns: 1fr;
  }
  .mosaic-gallery__item {
    grid-column: 1 !important;
    grid-row: span 1 !important;
  }
}
.gallery-page {
  padding-top: 150px;
}/*# sourceMappingURL=main.css.map */